import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/index";
import Select from "../../../components/Dropdown/index";
import InputField from "../../../components/InputField/index";
// import logo from "../../../assets/img/Layer x0020 1.png";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Avatar from "../../../assets/img/avatar.png";
import editbtn from "../../../assets/img/editbtn.svg";
// import trashbtn from "../../../assets/img/trashbtn.png";
import Alert from "../../../components/Alert";
import Expire from "../../../components/Expire";
import Loader from "../../../components/Loader";
import apiroutes from "../../../services/apiroutes";
import { request } from "../../../services/apiservice";
import { getAuth, getUser, setUser } from "../../../services/auth";

const EditProfile = () => {
  const loggedInUser = getUser();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  //  const [gender2, setGender2] = useState("");
  const [fullName, setFullName] = useState();
  const [valueTwo, setValueTwo] = useState("");
  const [fullNameNext, setFullNameNext] = useState("");
  const [nextMobile, setNextMobile] = useState("");
  const genderOptions = ["Male", "Female"];
  const optionsGender = genderOptions.map((x) => ({ label: x, value: x }));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const token = getAuth();
  const [user, setUserDetails] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (loggedInUser === null) {
      history.push("/");
      return false;
    } else {
      setUserDetails(loggedInUser);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loggedInUser === null) {
      return false;
    } else {
      setValueTwo(loggedInUser.PhoneNumber);
      setFullName(`${loggedInUser.FirstName} ${
        loggedInUser.LastName == null ? "" : loggedInUser.LastName
      }`);
      setEmail(loggedInUser.Email);
      setNextMobile(loggedInUser.NextOfKinPhone);
      setFullNameNext(loggedInUser.NextOfKinName);
      setGender(loggedInUser.Gender === 0 ? "Male" : "Female");
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/png", "image/jpeg"];
      if (validImageTypes.includes(fileType)) {
        setError("");
        setResponseMsg("Processing");
        setImage({
          preview: URL.createObjectURL(file),
          raw: file,
        });
        const formData = new FormData();
        formData.append("CustomerPhoto ", file);
        request(apiroutes.UpdatePhoto(), "post", formData, token)
          .then((res) => {
            if (res.data.code === 1 || res.data.code === "1") {
              Swal.fire({
                showConfirmButton: false,
                timer: 4000,
                text: `Photo Uploaded Successfully`,
                icon: "success",
              });
              const data = {
                Title: res.data.data.title,
                Email: res.data.data.email,
                FirstName: res.data.data.firstName,
                LastName: res.data.data.lastName,
                Gender: res.data.data.gender,
                Image: res.data.data.customerPhoto,
                IsConfirmed: res.data.data.accountIsConfirmed,
                Token: res.data.data.jwtToken,
                NextOfKinName: res.data.data.NextOfKinName,
                NextOfKinPhone: res.data.data.NextOfKinPhone,
                PhoneNumber: res.data.data.phoneNumber,
              };
              setUser(data);
              setResponseMsg(null);
            } else {
              // setLoading(false);
              Swal.fire({
                showConfirmButton: false,
                timer: 4000,
                text: `${res.data.shortDescription}`,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            setResponseMsg(null);
            setLoading(false);
            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              text: `${err.message}`,
              icon: "error",
            });
            //  setError(err.data.shortDescription);
          });
      } else {
        setError("Please select an image to upload");
      }
    }
  };

  const deleteImage = () => {
    setError(null);
    setResponseMsg("processing");
    request(apiroutes.DeletePhoto(loggedInUser.UserId), "delete", null, token)
      .then((res) => {
        setResponseMsg("Photo Deleted Successfully");
        const data = {
          Email: res.data.Object.Email,
          FirstName: res.data.Object.FirstName,
          Gender: res.data.Object.Gender,
          Image: res.data.Object.Image,
          IsActive: res.data.Object.IsActive,
          NextOfKinName: res.data.Object.NextOfKinName,
          NextOfKinPhone: res.data.Object.NextOfKinPhone,
          PhoneNumber: res.data.Object.PhoneNumber,
          ReferralCode: res.data.Object.ReferralCode,
          UserId: res.data.Object.UserId,
          UserType: res.data.Object.UserType,
        };
        setUser(data);
      })
      .catch((err) => {
        setError(err.data.shortDescription);
        setResponseMsg(null);
        setTimeout(() => setError(""), 4000);
        // setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setResponseMsg("Processing");
    setLoading(true);
    const nameArr = fullName.split(" ");
    const data = {
      firstName: nameArr[0],
      lastName: nameArr[1],
      sex: gender === "Male" ? 0 : 1,
      email: email,
      nextOfKinName: fullNameNext,
      nextOfKinMobile: nextMobile,
      mobile: valueTwo,
    };
    request(apiroutes.UpdatePassegerProfile(), "post", data, token)
      .then((res) => {

        if (res.data.code === 1 || res.data.code === "1") {
          setResponseMsg("Profile Details Updated Successfully");
          setLoading(false);
          const data = {
            Title: res.data.data.title,
            Email: res.data.data.email,
            FirstName: res.data.data.firstName,
            LastName: res.data.data.lastName,
            Gender: res.data.data.gender,
            Image: res.data.data.customerPhoto,
            IsConfirmed: res.data.data.accountIsConfirmed,
            NextOfKinName: res.data.data.nextOfKinName,
            NextOfKinPhone: res.data.data.nextOfKinPhone,
            PhoneNumber: res.data.data.phoneNumber,
          };
          setUser(data);
          setTimeout(() => window.location.reload(), 2500);
        } else {
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        // setResponseMsg(null);
        setLoading(false);
        // setError(err.message);
        // setTimeout(() => setError(""), 4000);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err.message}`,
          icon: "error",
        });
      });
  };

  const customStyles = {
    singleValue: () => {
      const color = "black";

      return { color };
    },
  };

  return (
    <div>
      <div className="row justify-content-md-center">
        <div className="col-md-6 col-sm -12">
          {error && (
            <Expire delay={3000}>
              <Alert className="alert text-center alert-danger" text={error} />
            </Expire>
          )}
          {responseMsg && !error && (
            <Expire delay={3000}>
              <Alert
                className="alert text-center alert-primary"
                text={responseMsg}
              />
            </Expire>
          )}
        </div>
      </div>
      <div className="profilePic text-center mb-5">
        <h2>Settings</h2>
        <div>
          {image.preview ? (
            <div className="profilepic-holder">
              <img
                src={image.preview}
                style={{ borderRadius: "50%" }}
                alt="dummy"
                width="80"
                height="80"
              />
            </div>
          ) : (
            <div className="profilepic-holder">
              {user === null ? (
                <img
                  src={Avatar}
                  //  src={logo}
                  style={{ borderRadius: "50%" }}
                  alt="dummy"
                  width="80"
                  height="80"
                />
              ) : (
                <img
                  src={
                    user.Image === null || user.Image === ""
                      ? Avatar
                      : user.Image
                  }
                  style={{ borderRadius: "50%" }}
                  alt={user.FirstName}
                  width="80"
                  height="80"
                />
              )}
            </div>
          )}
        </div>
        <br />
        <label htmlFor="upload-button">
          <img
            src={editbtn}
            alt=""
            className="mr-2"
            style={{ cursor: "pointer" }}
          />
          {/* <span href="#" style={{ cursor: "pointer", color: "blue" }}>Change Photo</span> */}
        </label>
        <input
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          onChange={handleChange}
        />
        {/* <img
          src={trashbtn}
          alt=""
          onClick={deleteImage}
          style={{ cursor: "pointer" }}
        /> */}
      </div>

      <div>
        <div className="setting-row">
          <div className="row row-grid">
            <div className="col-md-6 col-sm -12">
              <div>
                <label htmlFor="name">Full Name</label>
                <InputField
                  type="text"
                  placeholder="Enter Full Name"
                  onChangeMethod={(e) => setFullName(e.target.value)}
                  value={fullName}
                />
                <br />
                <br />
                <label htmlFor="email">Email</label>
                <InputField
                  type="email"
                  placeholder="Demmah16@gmail.com"
                  // onChangeMethod={(e) => setEmail(e.target.value)}
                  value={email}
                  readonly={true}
                />
                <br />
                <br />
                <label htmlFor="email">Next Of Kin Name</label>
                <InputField
                  type="text"
                  placeholder="Enter Name"
                  onChangeMethod={(e) => setFullNameNext(e.target.value)}
                  value={fullNameNext}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm -12">
              <div>
                <label htmlFor="sex">Sex</label>
                <Select
                  options={optionsGender}
                  handleChange={(e) => setGender(e.value)}
                  placeholder={gender}
                  value={gender === 0 ? "Male" : "Female"}
                  //  defaultValue={gender === 0 ? "Male" : "Female"}
                  styles={customStyles}
                />
                <br />
                <label htmlFor="number">Phone number</label>
                <InputField
                  type="number"
                  placeholder="08136478364"
                  // onChangeMethod={(e) => setValueTwo(e.target.value)}
                  value={valueTwo}
                  readonly={true}
                />
                <br />
                <br />
                <label htmlFor="number">Next Of Kin Phone number</label>
                <InputField
                  type="number"
                  placeholder="08136478364"
                  onChangeMethod={(e) => setNextMobile(e.target.value)}
                  value={nextMobile}
                />
              </div>
            </div>
          </div>
          <br />

          <div className="row row-grid">
            <div className="col-md-12">
              <div className="setting-button">
                <Button
                  //  text={loading ? <Loader dark={false} /> : "Update"}
                  text={
                    loading ? (
                      <div
                        style={{
                          display: "flex",
                          margin: "0 auto",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "200px",
                          transform: "translateX(-10px)",
                        }}
                      >
                        <Loader dark={false} />{" "}
                        <span
                          style={{
                            display: "inline-block",
                            transform: "translateX(-10px)",
                          }}
                        >
                          Processing
                        </span>
                      </div>
                    ) : (
                      "Update"
                    )
                  }
                  handleButtonClick={handleSubmit}
                  type="button"
                  disabled={!(fullName && gender && nextMobile && fullNameNext)}
                  btnstyle={{ backgroundColor: "#E21D00" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;

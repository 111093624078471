import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import dataBill from "../../../assets/img/data-bill.png";
import electricBill from "../../../assets/img/electric-bill.png";

import Swal from "sweetalert2";
import phoneBill from "../../../assets/img/phone-bill.png";
import tvBill from "../../../assets/img/tv-bill.png";
import Expire from "../../../components/Expire";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/NavBar";
import apiroutes from "../../../services/apiroutes";
import { requestTicketMan } from "../../../services/apiserviceTicketMan";
import { requestWallet } from "../../../services/apiserviceWallet";
import {
  getAuthPaybills,
  getAuthWallet,
  getUser,
  setUserWallet,
} from "../../../services/auth";
import MobileNavBar from "../MobileNavbar";
import SideBar from "../SideBar";
import AirtimeTopup from "./Components/AirtimeTopup";
import DataBundle from "./Components/DataBundle";
import Electricity from "./Components/Electricity";
import TvSubscription from "./Components/TvSubscription";
import "./index.css";

const BillsPayment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentWallet();
    generatePaymentRef();
  }, []);
  const [error, setError] = useState("");
  const loggedInUser = getUser();
  const walletDetails = getAuthWallet();
  const [responseMsg, setResponseMsg] = useState("");
  const [showBillsPaymentCard, setShowBillsPaymentCard] = useState(true);
  const [showAirtimeTopup, setShowAirtimeTopup] = useState(false);
  const [showDataBundle, setShowDataBundle] = useState(false);
  const [showTvSubscription, setShowTvSubscription] = useState(false);
  const [showElectricity, setShowElectricity] = useState(false);
  const [generatedPaymentRef, setGeneratedPaymentRef] = useState("");
  const [walletState, setWalletState] = useState(false);

  const history = useHistory();

  const getCurrentWallet = () => {
    requestWallet(
      apiroutes.GetWallet(loggedInUser?.PhoneNumber, loggedInUser?.Email),
      "get",
      null,
      walletDetails.access_token
    )
      .then((res) => {
        setWalletState(true);
        // localStorage.setItem("walletState", JSON.stringify(true))
        const data = {
          availableBalance: res.data.Payload.AvailableBalance,
          blockedBalance: res.data.Payload.BlockedBalance,
          email: res.data.Payload.Email,
          hasPin: res.data.Payload.HasPin,
          id: res.data.Payload.Id,
          ledgerBalance: res.data.Payload.LedgerBalance,
          phoneNumber: res.data.Payload.PhoneNumber,
        };
        setUserWallet(data);
      })
      .catch((err) => {
        if (
          err.response === undefined ||
          err.response.data?.description === "Wallet does not exist"
        ) {
          setWalletState(false);
        }
        setWalletState(false);
      });
  };

  const generatePaymentRef = async () => {
    // setShowAirtimeTopupProceed(false);
    let paybills = true;
    const token = getAuthPaybills();
    const refUser = getUser();
    const payload = { userPhone: refUser.PhoneNumber };

    requestTicketMan(apiroutes.GeneratePaybillsRef(), "post", payload, token)
      .then((res) => {
        setGeneratedPaymentRef(res.data.payload.transactionReference);

        // Swal.fire({
        //   showConfirmButton: false,
        //   timer: 4000,
        //   text: `Airtime Purchased Successfully.`,
        //   icon: "success",
        // });
      })
      .catch((err) => {
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `Error generating payment reference.`,
          icon: "error",
        });
      });
  };

  return (
    <div>
      <Navbar />

      <section className="billsPayment">
        <div className="container">
          <div className="dashboardArea">
            <SideBar />
            <div className="contentarea">
              <MobileNavBar />
              <div className="row justify-content-md-center">
                <div className="col-md-6 col-sm -12">
                  {error && (
                    <Expire delay={3000}>
                      <Alert
                        className="alert text-center alert-danger"
                        text={error}
                      />
                    </Expire>
                  )}
                  {responseMsg && !error && (
                    <Expire delay={3000}>
                      <Alert
                        className="alert text-center alert-primary"
                        text={responseMsg}
                      />
                    </Expire>
                  )}
                </div>
              </div>
              {showBillsPaymentCard && (
                <div className="row row-grid">
                  <div className="col-md-12 align-self-center">
                    <div className="pay-bills">
                      <h2 className="text-center">Bills Payment</h2>
                      <br />
                      <div className="bill-cards">
                        <div
                          className="bill-card"
                          onClick={() => {
                            setShowAirtimeTopup(true);
                            setShowBillsPaymentCard(false);
                          }}
                        >
                          <div className="">
                            <img
                              src={phoneBill}
                              width="70"
                              height="70"
                              alt="Security"
                            />
                          </div>
                          <br />
                          <h4>
                            <b>Top Up Airtime</b>
                          </h4>
                          <br />

                          <p>
                            Recharge your MTN, Glo, 9mobile and Airtel Lines
                          </p>
                        </div>
                        <div
                          className="bill-card"
                          onClick={() => {
                            setShowDataBundle(true);
                            setShowBillsPaymentCard(false);
                          }}
                        >
                          <div className="">
                            <img
                              src={dataBill}
                              width="70"
                              height="70"
                              alt="Security"
                            />
                          </div>
                          <br />
                          <h4>
                            <b>Data Bundle</b>
                          </h4>
                          <br />

                          <p>Buy MTN, Glo, 9mobile and Airtel Data</p>
                        </div>
                        <div
                          className="bill-card"
                          onClick={() => {
                            setShowTvSubscription(true);
                            setShowBillsPaymentCard(false);
                          }}
                        >
                          <div className="">
                            <img
                              src={tvBill}
                              width="70"
                              height="70"
                              alt="Security"
                            />
                          </div>
                          <br />
                          <h4>
                            <b>Tv Subscription</b>
                          </h4>
                          <br />

                          <p>Pay for cable TV Seamlessly</p>
                        </div>
                        <div
                          className="bill-card"
                          onClick={() => {
                            setShowElectricity(true);
                            setShowBillsPaymentCard(false);
                          }}
                        >
                          <div className="">
                            <img
                              src={electricBill}
                              width="70"
                              height="70"
                              alt="Security"
                            />
                          </div>
                          <br />
                          <h4>
                            <b>Electricity</b>
                          </h4>
                          <br />

                          <p>Pay for Prepaid/Postpaid electricity on-the-go</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <AirtimeTopup
                showAirtimeTopup={showAirtimeTopup}
                setShowAirtimeTopup={setShowAirtimeTopup}
                setShowBillsPaymentCard={setShowBillsPaymentCard}
                generatedPaymentRef={generatedPaymentRef}
                // walletState={true}
                walletState={walletState}
              />
              <DataBundle
                showDataBundle={showDataBundle}
                setShowDataBundle={setShowDataBundle}
                setShowBillsPaymentCard={setShowBillsPaymentCard}
                generatedPaymentRef={generatedPaymentRef}
                walletState={walletState}
              />
              <TvSubscription
                showTvSubscription={showTvSubscription}
                setShowTvSubscription={setShowTvSubscription}
                setShowBillsPaymentCard={setShowBillsPaymentCard}
                generatedPaymentRef={generatedPaymentRef}
                walletState={walletState}
              />
              <Electricity
                showElectricity={showElectricity}
                setShowElectricity={setShowElectricity}
                setShowBillsPaymentCard={setShowBillsPaymentCard}
                generatedPaymentRef={generatedPaymentRef}
                walletState={walletState}
              />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BillsPayment;

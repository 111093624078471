import { convertDistance, getDistance } from "geolib";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import circleIcon from "../../assets/img/tick-circle.png";
import errorIcon from "../../assets/img/x-circle.png";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Navbar from "../../components/NavBar";
import {
  convertTime24to12,
  formatCurrency,
  formatDepartureDateWithMonthName,
} from "../../components/util";
import apiroutes from "../../services/apiroutes";
import { requestBooking } from "../../services/apiservice";
import { getAuth } from "../../services/auth";
import "./index.css";

export const BookingStatusComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // setUserDepartureTerminal(bookingData?.DepartureTerminald);
    // getTerminalById();
    // setTimeout(()=> getDistance(), [3000])
    setTimeout(() => getTerminalById(), [3000]);
  }, []);
  const history = useHistory();
  const token = getAuth();
  const [loading, setLoading] = useState(false);
  const [userLat, setUserLat] = useState("");
  const [userLng, setUserLng] = useState("");
  // const [userDepartureTerminal, setUserDepartureTerminal] = useState("");
  const [userDepartureTerminalLat, setUserDepartureTerminalLat] = useState("");
  const [userDepartureTerminalLng, setUserDepartureTerminalLng] = useState("");
  const [checkinError, setCheckinError] = useState(false);
  const [checkinSuccess, setCheckinSuccess] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alternativeDate, setAlternativeDates] = useState(
    new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738))
  );

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else if (!navigator.geolocation) {
      // alert("You must grant location access to Check In!!!");
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `You must grant location access to Check In.`,
        icon: "error",
      });
      window.location.reload();
    } else {
      // alert("You must grant location access to Check In!!!");
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `You must grant location access to Check In.`,
        icon: "error",
      });
      window.location.reload();
    }
  };

  const showPosition = (position) => {
    // alert("Latitude: " + position.coords.latitude +
    // "<br>Longitude: " + position.coords.longitude);
    setUserLat(position.coords.latitude);
    setUserLng(position.coords.longitude);
    const distance = getDistance(
      { latitude: userLat, longitude: userLng },
      {
        latitude: userDepartureTerminalLat,
        longitude: userDepartureTerminalLng,
      }
    );

    if (convertDistance(distance, "km") < 300) {
      // alert("YOU ARE CHECKED IN");
      handleCheckIn();
    } else {
      // alert("YOU MUST BE LESS THAN 300KM TO THE TERMINAL TO CHECKED IN");
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `YOU MUST BE LESS THAN 300KM TO THE TERMINAL TO CHECKED IN.`,
        icon: "error",
      });
    }
  };

  const handleCheckIn = () => {
    // setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    // const verify = {
    //   UserName: phoneNumber,
    // };
    // request(apiroutes.CheckPassengerIn("OH-909080000", "29"), "get", null, token)
    requestBooking(
      apiroutes.CheckPassengerIn(
        bookingData[0]?.bookingReferenceCode,
        bookingData[0]?.route.departureTerminal?.id
      ),
      "get",
      null,
      token
    )
      .then((res) => {
        if (res.data.code === 1 || res.data.code === "1") {
          setLoading(false);
          setCheckinSuccess(true);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `YOU ARE CHECKED IN!!!`,
            icon: "success",
          });
          // alert("YOU ARE CHECKED IN!!!");
          setTimeout(() => history.push("/"), 1500);
        } else {
          setLoading(false);
          setErrorMessage(res.data.shortDescription);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
          // alert("ERROR WHILE TRYING TO CHECK YOU IN, PLEASE TRY AGAIN!!!")
          // setError(res.data.ShortDescription);
          setCheckinError(true);
          // setTimeout(() => setCheckinError(true), 1500)
        }
      })
      .catch((err) => {
        // setResponseMsg(null);
        setLoading(false);
        setCheckinError(true);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `ERROR WHILE TRYING TO CHECK YOU IN, PLEASE TRY AGAIN!!!`,
          icon: "error",
        });
        // alert("ERROR WHILE TRYING TO CHECK YOU IN, PLEASE TRY AGAIN!!!");
        setTimeout(() => window.location.reload(), 1500);
        // window.location.reload();
      });
  };

  const getTerminalById = () => {
    setUserDepartureTerminalLat(
      bookingData[0]?.route.departureTerminal?.latitude
    );
    setUserDepartureTerminalLng(
      bookingData[0]?.route.departureTerminal?.longitude
    );
  };

  // const getTerminalById = () => {
  //   // request(apiroutes.GetTerminalById(29), "get", null, token)
  //   request(
  //     apiroutes.GetTerminalById(parseInt(bookingData[0]?.route.departureTerminal.id)),
  //     "get",
  //     null,
  //     token
  //   )
  //     .then((res) => {
  //       setUserDepartureTerminalLat(res.data.Object.Latitude);
  //       setUserDepartureTerminalLng(res.data.Object.Longitude);
  //     })
  //     .catch((err) => {
  //       // alert(err);
  //       Swal.fire({
  //         showConfirmButton: false,
  //         timer: 4000,
  //         text: `${err}`,
  //         icon: "error",
  //       });
  //     });
  // };

  //   const distance = getDistance(
  //     { latitude: 6.5437696, longitude: 3.3488896 },
  //     { latitude: 6.518806, longitude: 3.367547 }
  // )


  // const getDistance = (e) => {
  //   e.preventDefault();
  //   // setLoading(true);

  //   // initialize services
  //   const origin = [6.5437696, 3.3488896];
  //   const destination = [6.518806, 3.367547];
  //   // const service = new window.google.maps.DistanceMatrixService();
  //   const requestInfo = {
  //     origins: [origin],
  //     destinations: [destination],
  //     travelMode: "DRIVING",
  //     unitSystem: window.google.maps.UnitSystem.METRIC,
  //     avoidHighways: false,
  //     avoidTolls: false,
  //   };

  //   service.getDistanceMatrix(requestInfo, callback);

  //   function callback(response, status) {
  //     let distance = response.rows[0].elements[0].distance.text.split("km");
  //     const details = {
  //       OnewayDistanceApart: parseInt(distance[0]),
  //     };
  //   }
  // };

  let bookingData = JSON.parse(localStorage.getItem("allBookingStatus"));
  let total = bookingData.filter((booking) => booking.amount != null);
  const findSum = (arr, size) => {
    let sum = 0;

    for (let i = 0; i < size; i++) {
      sum += arr[i].amount;
    }

    return sum;
  };

  total = findSum(total, bookingData.length);

  const createdDate = bookingData[0]?.createdDate
    ? new Date(bookingData[0]?.createdDate)
    : alternativeDate;
    
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const dateDeparture = bookingData[0]?.vehicleTripRegistrations?.departureDate
    ? formatDepartureDateWithMonthName(
        bookingData[0]?.vehicleTripRegistrations?.departureDate?.month,
        bookingData[0]?.vehicleTripRegistrations?.departureDate?.day,
        bookingData[0]?.vehicleTripRegistrations?.departureDate?.year
      )
    : dateTimeFormat.format(alternativeDate);

  // const dateDeparture = bookingData[0]?.DepartureDate
  //   ? new Date(bookingData[0]?.departureDate)
  //   : alternativeDate;

  // let setNumbers = localStorage.getItem("selectedSeats");
  // setNumbers = JSON.parse(setNumbers);


  //   const nf = new Intl.NumberFormat();

  const toggleCheckinErrorClose = () => {
    setCheckinError(false);
  };
  const toggleCheckinSuccessClose = () => {
    setCheckinError(false);
  };
  const toggleticketModalClose = () => {
    setTicketModal(false);
  };

  const width = 450;
  const heightSe = 380;
  const widthh = 500;
  const heightt = 550;
  const modalTitle = "Bus Ticket";

  const modalBodyError = (
    <div className="row">
      <div className="col-md-12">
        <div className="text-center">
          <img src={errorIcon} alt="" width="70" height="70" />
        </div>
        <p
          className="wallet-onboarding-p text-center"
          style={{ fontWeight: "bold", fontSize: "1.6rem", margin: "20px 0" }}
        >
          Check-In Failed
        </p>
        {errorMessage === "" ? (
          <>
            <p className="wallet-onboarding-p text-center">
              We could not check you in because it's almost departure time.
            </p>
            <p className="wallet-onboarding-p text-center">
              Please go to the counter for support.
            </p>
          </>
        ) : (
          <p className="wallet-onboarding-p text-center">{errorMessage}.</p>
        )}
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-8 offset-md-2">
            <div className="text-center">
              <Button
                type="button"
                handleButtonClick={toggleCheckinErrorClose}
                text="Close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const modalBodySuccess = (
    <div className="row">
      <div className="col-md-12">
        <div className="text-center">
          <img src={circleIcon} alt="" width="70" height="70" />
        </div>
        <p
          className="wallet-onboarding-p text-center"
          style={{ fontWeight: "bold", fontSize: "1.6rem", margin: "20px 0" }}
        >
          Check-In Successful
        </p>

        <p className="wallet-onboarding-p text-center">
          Enjoy your trip and thanks for choosing us.
        </p>
        <p className="wallet-onboarding-p text-center">
          A copy of this ticket has been sent to your email.
        </p>
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-8 offset-md-2">
            <div className="text-center">
              <Button
                type="button"
                handleButtonClick={() => setTicketModal(true)}
                text="View Ticket"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const modalTicketBody = (
    <div className="pt-2 pb-2">
      <div className="">
        <div className="col-sm-12 col-md-12 ">
          <div className="check-in-ticket" style={{ padding: "0 20px" }}>
            <div className="ticket-qr mb-1">
              <div className="qr-bg">
                <QRCode
                  value={bookingData[0]?.bookingReferenceCode}
                  size="150"
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Departure:</p>
              <p className="">
                <strong>
                  {bookingData[0]?.route?.departureTerminal?.terminalName}
                </strong>
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Destination:</p>
              <p className="">
                <strong>
                  {bookingData[0]?.route?.destinationTerminal?.terminalName}
                </strong>
              </p>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Booking Date:</p>
              <p className="">
                <strong>{dateTimeFormat.format(createdDate)}</strong>{" "}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Departure Date:</p>
              <p className="">
                <strong>{dateDeparture}</strong>
                {/* <strong>{dateTimeFormat.format(dateDeparture)}</strong> */}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>RefCode:</p>
              <p>
                <strong>{bookingData[0]?.bookingReferenceCode}</strong>
              </p>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Amount Paid:</p>
              <p className="">
                <strong>
                  ₦
                  {bookingData[0].amount === null
                    ? 0
                    : formatCurrency(bookingData[0]?.amount)}
                </strong>{" "}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Booked Seat:</p>
              <p className="">
                <strong>
                  {bookingData.map((item) => item.seatNumber.toString())}
                </strong>
                {/* <strong>{bookingData[0]?.SeatNumber}</strong> */}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              {/* <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Bus Type:</p> */}
              <p className="desc-para">
                {/* <strong>{bookingData?.SeatNumber}</strong> */}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              {/* <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Trip Type:</p> */}
              <p className="desc-para">
                {/* <strong>{bookingData?.SeatNumber}</strong>  */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Navbar />
      <Modal
        width={width}
        height={heightSe}
        visible={checkinError}
        body={modalBodyError}
        handleClose={toggleCheckinErrorClose}
      />
      <Modal
        width={width}
        height={heightSe}
        visible={checkinSuccess}
        body={modalBodySuccess}
        handleClose={toggleCheckinSuccessClose}
      />
      <Modal
        width={widthh}
        height={heightt}
        visible={ticketModal}
        title={modalTitle}
        body={modalTicketBody}
        handleClose={toggleticketModalClose}
      />
      <section className="">
        <div className="container h-100">
          <h5 style={{ fontWeight: "bold", paddingTop: "150px" }}>
            Booking Details
          </h5>
          {bookingData[0]?.bookingStatus === 2 ? (
            ""
          ) : (
            <div className="">
              {bookingData[0]?.isPrinted === false ? (
                <Button
                  text={loading ? <Loader dark={false} /> : "Check In"}
                  handleButtonClick={() => getLocation()}
                  type="button"
                  btnstyle={{
                    background: "red",
                    color: "white",
                    margin: "20px 0px",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    width: "200px",
                  }}
                  // disabled={!refCode}
                />
              ) : (
                <Button
                  text={loading ? <Loader dark={false} /> : "View Ticket"}
                  handleButtonClick={() => setTicketModal(true)}
                  type="button"
                  btnstyle={{
                    background: "red",
                    color: "white",
                    margin: "20px 0px",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    width: "200px",
                  }}
                  // disabled={!refCode}
                />
              )}
            </div>
          )}
          {bookingData.length === 1 ? (
            <div className="bookingStatusDetails h-100">
              <div className="book-det">
                <div className="info-det">
                  <label className="det-label">Date of Booking</label>
                  <p>
                    {/* <strong>{bookingData.createdDate}</strong> */}
                    <strong>
                      {dateTimeFormat.format(createdDate)}
                      {/* {dateTimeFormat.format(new Date(bookingData[0]?.createdDate))} */}
                    </strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Booking Status</label>
                  <p>
                    <strong>
                      {bookingData[0]?.bookingStatus === 0 ? (
                        <p className="blink_me" style={{ color: "red" }}>
                          Pending
                        </p>
                      ) : bookingData[0]?.bookingStatus === 1 ? (
                        <p style={{ color: "#6AC28F" }}>Confirmed</p>
                      ) : (
                        <p style={{ color: "red" }}>Cancelled</p>
                      )}
                    </strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">No. of Tickets</label>
                  <p>
                    <strong>{bookingData.length}</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Seat Status</label>
                  <p>
                    <strong>Booked</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Amount</label>
                  <p>
                    <strong>₦{formatCurrency(total || 0)}</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Route</label>
                  <p>
                    <strong>{bookingData[0]?.route?.routeName}</strong>
                  </p>
                </div>
              </div>

              <h3 className="traveller-header">Main Traveller Details:</h3>
              <div className="book-det">
                <div className="info-det">
                  <label className="det-label">Full Name</label>
                  <p>
                    <strong>{bookingData[0]?.fullName}</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Time and Date</label>
                  <p>
                    {/* <strong>{bookingData.DepartureDate} <br/> {bookingData.DepartureTime}</strong> */}
                    <strong>
                      {dateDeparture} <br />{" "}
                      {bookingData[0]?.vehicleTripRegistrations?.trip && convertTime24to12(
                        bookingData[0]?.vehicleTripRegistrations?.trip
                          ?.departureTime
                      )}
                    </strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Ref Code</label>
                  <p>
                    <strong>{bookingData[0]?.bookingReferenceCode}</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Seat Number(s)</label>
                  <p>
                    {/* <strong>{setNumbers}</strong> */}
                    <strong>
                      {bookingData.map((item) => item.seatNumber)}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="book-det">
                <div className="info-det">
                  <label className="det-label">Sex</label>
                  <p>
                    <strong>
                      {bookingData[0]?.gender === 0 ? "Male" : "Female"}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            bookingData.map((bookingData) => (
              <div className="bookingStatusDetails h-100">
                {bookingData?.HasReturn === true ? (
                  <h3 className="traveller-header">Departure Trip Details:</h3>
                ) : (
                  <h3 className="traveller-header">Returning Trip Details:</h3>
                )}
                <div className="book-det">
                  <div className="info-det">
                    <label className="det-label">Date of Booking</label>
                    <p>
                      {/* <strong>{bookingData.DateCreated}</strong> */}
                      <strong>
                      {dateTimeFormat.format(createdDate)}
                      </strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Booking Status</label>
                    <p>
                      <strong>
                        {bookingData?.bookingStatus === 0 ? (
                          <p className="blink_me" style={{ color: "red" }}>
                            Pending
                          </p>
                        ) : bookingData?.bookingStatus === 1 ? (
                          <p style={{ color: "#6AC28F" }}>Confirmed</p>
                        ) : (
                          <p style={{ color: "red" }}>Cancelled</p>
                        )}
                      </strong>
                    </p>
                  </div>
                  {/* <div className="info-det">
                  <label className="det-label">No. of Tickets</label>
                  <p>
                    <strong>{bookingData.length}</strong>
                  </p>
                </div> */}
                  <div className="info-det">
                    <label className="det-label">Seat Status</label>
                    <p>
                      <strong>Booked</strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Amount</label>
                    <p>
                      <strong>
                        ₦
                        {bookingData.Amount === null
                          ? 0
                          : formatCurrency(bookingData?.amount)}
                      </strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Route</label>
                    <p>
                      <strong>{bookingData?.route?.routeName}</strong>
                    </p>
                  </div>
                </div>

                <h3 className="traveller-header">Main Traveller Details:</h3>
                <div className="book-det">
                  <div className="info-det">
                    <label className="det-label">Full Name</label>
                    <p>
                      <strong>{bookingData?.fullName}</strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Date and Time</label>
                    <p>
                      {/* <strong>{bookingData.DepartureDate} <br/> {bookingData.DepartureTime}</strong> */}
                      <strong>
                      {dateDeparture} <br />{" "}
                      {bookingData[0]?.vehicleTripRegistrations?.trip && convertTime24to12(
                        bookingData?.vehicleTripRegistrations?.trip
                          ?.departureTime
                      )}
                      </strong>
                    </p>
                  </div>

                  <div className="info-det">
                    <label className="det-label">Ref Code</label>
                    <p>
                      <strong>{bookingData?.bookingReferenceCode}</strong>
                    </p>
                  </div>

                  <div className="info-det">
                    <label className="det-label">Seat Number(s)</label>
                    <p>
                      <strong>
                        {bookingData.seatNumber}
                        {/* {bookingData.length > 1 ?
                      bookingData.map((item) => item.SeatNumber.toString() + ", " )
                      : 
                       bookingData.map((item) => item.SeatNumber.toString() )} */}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="book-det">
                  <div className="info-det">
                    <label className="det-label">Sex</label>
                    <p>
                      <strong>
                        {bookingData?.gender === 0 ? "Male" : "Female"}
                      </strong>
                    </p>
                  </div>
                  {/* <div className="info-det">
                  <label className="det-label">Vehicle Details</label>
                  <p>
                    <strong>{bookingData[0]?.VehicleRegistrationNumber}</strong>
                  </p>
                </div> */}
                  {/* <div className="info-det">
                    <label className="det-label">Phone Number</label>
                    <p>
                      <strong>{bookingData?.PhoneNumber}</strong>
                    </p>
                  </div> */}
                </div>
              </div>
            ))
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default BookingStatusComponent;

import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/NavBar/index";
import apiroutes from "../../../services/apiroutes";
import { requestBooking } from "../../../services/apiservice";
import { getAuth, getUser } from "../../../services/auth";
import MobileNavBar from "../MobileNavbar/index";
import SideBar from "../SideBar/index";
import "./booking.css";
import Details from "./bookingdetails";
import History from "./bookinghistory";

export const BookingHistory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [history, setHistory] = useState(false);
  const [details, setDetails] = useState(false);
  const [bookingInfo, setBookingInfo] = useState();
  const [bookingItem, setBookingItem] = useState();
  const [loader, setLoader] = useState(true);
  const loggedInUser = getUser();
  const token = getAuth();

  const handleChange = (item) => {
    setLoader(false);
    setHistory(false);
    setDetails(true);
    setBookingItem(item);
  };

  const handleDetails = () => {
    setHistory(true);
    setDetails(false);
  };

  useEffect(() => {
    if (loggedInUser === null) {
      history.push("/");
      return false;
    } else {
      bookingToken();
    }
    // eslint-disable-next-line
  }, []);

  const bookingToken = () => {
    requestBooking(
      apiroutes.BookingHistory(loggedInUser.Email),
      "get",
      null,
      token
    )
      .then((res) => {
        setBookingInfo(res?.data?.data?.userData);
        setLoader(false);
        setHistory(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const historyContent = history ? (
    <History onclick={handleChange} product={bookingInfo} />
  ) : null;

  const detailContent = details ? (
    <Details onclick={handleDetails} details={bookingItem} />
  ) : null;
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="dashboardArea">
          <SideBar />
          <div className="contentarea">
            <MobileNavBar />
            {loader ? (
              <div className="text-center">
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <>
                {historyContent}
                {detailContent}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BookingHistory;

import config from "../configs";




const makeAPICallWoven = async (
  { method = "POST", payload = null },
  ...customConfigs
) => {
  const headers = {
    "Content-type": "application/json",
    // "Authorization": "Bearer FLWPUBK-865c4665eee6ba09ed0df9ebae80878e-X",
    "api_secret": "vb_ls_b070b000cf0a632782b7a78aa9573a59fc1a096cbe2a"
    // "api_secret": "vb_ts_d3c4ce6843714ac50f447d218bbf4bd9784430362ef2"
    // "api_secret": `${config.WOVEN_KEYS_LIVE}`,
  };

  const configs = {
    method,
    headers,
    ...customConfigs,
  };

  if (payload) configs.body = JSON.stringify(payload);

  return window
    .fetch("https://api.woven.finance/v2/api/vnubans/create_customer", configs)
    .then((response) => response.json())
    .catch((err) => err);
};

export default makeAPICallWoven;
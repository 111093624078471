import React, { useState } from "react";
import bookingcode from "../../../assets/img/bookingcode.png";
import { formatCurrency } from "../../../components/util";

const History = ({ onclick, product }) => {
  const [alternativeDate, setAlternativeDates] = useState(
    new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738))
  );
  const newproduct = product;

  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <div>
      {newproduct.length === 0 ? (
        <div>
          <div className="col-md-11 nobookinghistory">
            <h3>Booking History</h3>
            <div className="nobookinghistoryimg">
              <img src={bookingcode} alt="" />
            </div>
            <div className="referral-code">
              <p>You are yet to make a booking</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="booking-history">
          {newproduct?.map((item, index) => (
            <div
              className="booking-details"
              onClick={() => {
                onclick(item);
              }}
              key={index}
            >
              <div className="row row-grid">
                <div className="col-md-6">
                  <div>
                    <h3>{item?.routeName}</h3>
                    {/* <p>
                      {item??.departureDate
                        ? `${getMonthName(
                            item?.departureDate.month
                          )} ${
                            item?.departureDate.day
                          }, ${
                            item?.departureDate.year
                          }`
                        : dateTimeFormat.format(alternativeDate)}
                    </p> */}
                    <p>
                      {dateTimeFormat.format(
                        new Date(item?.departureDate || alternativeDate)
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-md-right text-sm-left">
                    <p>
                      {item?.bookingStatus === 0
                        ? "Pending"
                        : item?.bookingStatus === 1
                        ? "Approved"
                        : item?.bookingStatus === 2
                        ? "Cancelled"
                        : item?.bookingStatus === 3
                        ? "Created"
                        : item?.bookingStatus === 4
                        ? "Declined"
                        : item?.bookingStatus === 5
                        ? "Expired"
                        : item?.bookingStatus === 6
                        ? "Failed"
                        : "Not-Specified"}
                    </p>
                    <h4>₦{formatCurrency(item?.totalAmount || item?.amount || 0)}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default History;

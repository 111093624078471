import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../assets/img/Layer x0020 1.png";
import eye from "../../assets/img/eye-24-512.png";
import eyeHidden from "../../assets/img/invisible 2.png";
import tick from "../../assets/img/tick-circle.png";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Expire from "../../components/Expire";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const PasswordComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordField, setPasswordField] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordFieldConfirm, setPasswordConfirmField] = useState("password");
  const [modalVisibleConfirm, setVisibleConfirm] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  const token = getAuth();

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
    setPasswordField(passwordField === "text" ? "password" : "text");
  };

  const showConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
    setPasswordConfirmField(
      passwordFieldConfirm === "text" ? "password" : "text"
    );
  };

  const toggleModalClose = () => {
    setVisibleConfirm(true);
  };

  const checkEmail = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Please provide a valid email address`,
        icon: "error",
      });
      return false;
    } else {
      toggleModalConfirm();
    }
  };

  const toggleModalConfirm = (e) => {
    e.preventDefault();

    const userEmail = JSON.parse(localStorage.getItem('forgotPasswordPayload'));
    if (newPassword === confirmPassword) {
      setError("");
      setResponseMsg("");
      setLoading(true);
      const signDetails = {
        username: userEmail?.username,
        newPassword: newPassword,
        otp: userEmail?.activationCode,
      };
      request(apiroutes.ResetPassword(), "post", signDetails, token)
        .then((res) => {
          if (
            res.data.code === 1 || res.data.code === "1"
          ) {

             setLoading(false);
            // setResponseMsg("Password Reset Successful");
            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              // text: `${res.data.shortDescription}`,
              text: `Password Reset Successful`,
              icon: "success",
            });
            // const data = {
            //   Title: res.data.data.title,
            //   Email: res.data.data.email,
            //   FirstName: res.data.data.firstName,
            //   LastName: res.data.data.lastName,
            //   Gender: res.data.data.gender,
            //   Image: res.data.data.customerPhoto,
            //   IsConfirmed: res.data.data.accountIsConfirmed,
            //   Token: res.data.data.jwtToken,
            //   NextOfKinName: res.data.data.NextOfKinName,
            //   NextOfKinPhone: res.data.data.NextOfKinPhone,
            //   PhoneNumber: res.data.data.phoneNumber,
            // };
            // setUser(data);
            // setAuth(res.data.data.jwtToken);
            history.push("/login");


          } else {
            setLoading(false);
            // setResponseMsg("Password Reset Successful");
            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              text: `${res.data.shortDescription}`,
              icon: "error",
            });
         
            // setVisibleConfirm(true);
          }
        })
        .catch((err) => {
          setResponseMsg(null);
          setLoading(false);
          setError(err.message);
          setTimeout(() => setError(""), 4000);
        });
    } else {
      setError("Password Doesn't Match");
    }
  };

  const width = 500;
  const height = 350;
  const modalTitle = "Password reset successful";
  const modalBody = (
    <div className="text-center">
      <img src={tick} alt="" className="tick-img" />
      <br />
      <br />
      <p className="success-password">
        <Link to="/">Go to Dashboard</Link>
      </p>
    </div>
  );

  return (
    <div>
      <Modal
        width={width}
        height={height}
        visible={modalVisibleConfirm}
        title={modalTitle}
        body={modalBody}
        handleClose={toggleModalClose}
      />
      <div className="d-md-flex h-md-100">
        <div className="col-md-5 bg-indigo h-md-100">
          <div className="row">
            <div className="col-md-12">
              <div className="this-position">
                <Link to="/">
                  <img src={logo} alt="" className="home-logo" />
                </Link>
                <h1>Revolutionizing road transportation in Africa</h1>
                <p>
                  GIGM is a technologically powered mobility platform providing
                  MOBILITY services to people across Africa
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 h-md-100">
          <div className="d-md-flex align-items-center justify-content-center h-md-100">
            <div className="pt-0">
              <div className="auth-section">
                <div className="row justify-content-md-center">
                  <div className="col-6">
                    {error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-danger"
                          text={error}
                        />
                      </Expire>
                    )}
                    {responseMsg && !error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-primary"
                          text={responseMsg}
                        />
                      </Expire>
                    )}
                  </div>
                </div>
                <h1>Forgot Password</h1>
                <p className="general-par">Kindly fill in the details below</p>
                {/* <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email" className="label-auth">
                      Verification Code
                    </label>
                    <InputField
                      type="text"
                      placeholder="Enter verification code"
                      onChangeMethod={(e) =>
                        setVerificationCode(e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="label-auth">
                      Email
                    </label>
                    <InputField
                      type="text"
                      placeholder="Enter email"
                      onChangeMethod={(e) => setEmail(e.target.value)}
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <br /> */}
                <div className="" style={{maxWidth: "400px",margin: "0 auto"}}>
                  <div className="col-md-12">
                    <label htmlFor="password" className="label-auth">
                      New Password
                    </label>
                    <div className="pass-wrapper">
                      <InputField
                        type={passwordField}
                        onChangeMethod={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter New Password"
                        autoComplete="new-password"
                      />

                      {showPassword ? (
                        <img
                          src={eye}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible img-size"
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="confirmPassword" className="label-auth">
                      Confirm New Password
                    </label>
                    <div className="pass-wrapper">
                      <InputField
                        type={passwordFieldConfirm}
                        onChangeMethod={(e) =>
                          setConfirmPassword(e.target.value)
                        }
                        placeholder="Confirm New Password"
                      />

                      {showConfirmPassword ? (
                        <img
                          src={eye}
                          alt=""
                          onClick={showConfirmPasswordToggle}
                          className="passwordVisible img-size"
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          alt=""
                          onClick={showConfirmPasswordToggle}
                          className="passwordVisible"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                    <Button
                      handleButtonClick={toggleModalConfirm}
                      // handleButtonClick={toggleModalConfirm}
                      // text={
                      //   loading ? <Loader dark={false} /> : "Reset Password"
                      // }
                      text={
                        loading ? (
                          <div
                            style={{
                              display: "flex",
                              margin: "0 auto",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "200px",
                              transform: "translateX(-10px)",
                            }}
                          >
                            <Loader dark={false} />{" "}
                            <span
                              style={{
                                display: "inline-block",
                                transform: "translateX(-40px)",
                              }}
                            >
                              Processing
                            </span>
                          </div>
                        ) : (
                          "Reset Password"
                        )
                      }
                      type="button"
                      disabled={
                        !(
                          // email &&
                          newPassword &&
                          confirmPassword
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PasswordComponent;

import React, { useEffect, useState } from "react";
import Appinstall from "../../components/Appinstall";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import { getAuth, getUser } from "../../services/auth";
import "./index.css";
// import googleBtn from "../../assets/img/Google Play - eng.png";
// import appleBtn from "../../assets/img/App Store - eng.png";
// import mobileapp1 from "../../assets/img/iphone1.png";
// import mobileapp2 from "../../assets/img/iphone2.png";
import { Link } from "react-router-dom";
import circleIcon from "../../assets/img/tick-circle.png";
// import { useHistory } from "react-router";
import Button from "../../components/Button/index";
// import Select from "../../components/Dropdown/index";
import GooglePlacesAutocomplete from "react-google-places-autocomplete"; // getLatLng, // geocodeByAddress,
import PhoneInput from // , { formatPhoneNumber }
  "react-phone-number-input";
import Swal from "sweetalert2";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import config from "../../configs";
import apiroutes from "../../services/apiroutes";
import { request, requestSetting } from "../../services/apiservice";

export const SuggestedRouteComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (loggedInUser === null) {
      return false;
    } else {
      setFullName(loggedInUser?.FirstName);
      setEmail(loggedInUser?.Email);
      setValue(`+234${loggedInUser?.PhoneNumber.slice(1)}`);
    }
    // eslint-disable-next-line
  }, []);
  const loggedInUser = getUser();
  const token = getAuth();
  const [departure, setDeparture] = useState("");
  const [departureState, setDepartureState] = useState("");
  const [arrival, setArrival] = useState("");
  const [arrivalState, setArrivalState] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setVisible] = useState(false);

  // const stateOptions = ["Enugu", "Lagos"];
  // const optionState = stateOptions.map((x) => ({ label: x, value: x }));
  const apiKey = config.GOOGLE_API;

  const handleSuggestRoute = () => {
    // e.preventDefault();
    setLoading(true);
    const suggestDetails = {
      DepartureState: departureState,
      DepartureLocation: departure.label,
      ArrivalState: arrivalState,
      ArrivalLocation: arrival.label,
      Email: email,
      PhoneNumber: value,
    };

    requestSetting(apiroutes.SuggestRoute(), "post", suggestDetails, token)
      .then((res) => {
        setLoading(false);
        setVisible(true);
      })
      .catch((err) => {
        Swal.fire({
          showConfirmButton: false,
          timer: 10000,
          text: err.response?.status,
          // text: `Error validating your request, Please try again!`,
          icon: "error",
        });
        setLoading(false);
      });
  };

  const toggleModalClose = () => {
    setVisible(true);
  };

  const checkEmail = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Please provide a valid email address`,
        icon: "error",
      });
      return false;
    } else if (value.length < 14 || value.length > 14) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Phone No should be 11 digit`,
        icon: "error",
      });
    } else {
      handleSuggestRoute();
    }
  };

  const width = 450;
  const height = 400;
  const modalBody = (
    <div className="row">
      <div className="col-md-12">
        <div className="text-center">
          <img src={circleIcon} alt="" />
        </div>
        <p className="wallet-onboarding-p text-center mt-3">
          You’ve succesfully submitted a route
        </p>
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-8 offset-md-2">
            <div className="text-center">
              <Link to="./">
                <Button
                  type="button"
                  text="Go Home"
                  btnstyle={{
                    background: "#E21D00",
                    color: "white",
                    margin: "10px 0px",
                    // fontFamily: "Euclid Circular B",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // const customStyles = {
  //   inputField_Wrap: () => {
  //     const color = "red";
  //     const fontSize = "8px";

  //     return { color, fontSize };
  //   },
  // };

  return (
    <div id="top">
      <Modal
        width={width}
        height={height}
        visible={modalVisible}
        body={modalBody}
        handleClose={toggleModalClose}
      />
      <Navbar />
      <section className="hero-bg pb-5">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="d-flex align-items-center flex-column">
              <h3
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "2.2rem",
                }}
              >
                Suggest a Route
              </h3>
              <br />
              <p style={{ fontSize: ".8rem", opacity: ".7" }}>
                We plan on serving you better, suggest a route we should go to
                now!
              </p>
              <br />

              <section id="suggest-form">
                <div
                  className="suggest d-flex align-items-center flex-column"
                  // style={{ padding: "20px 40px" }}
                >
                  <h3
                    style={{
                      color: "white",
                      fontWeight: "700",
                      // fontSize: "2.2rem",
                      paddingTop: "25px",
                    }}
                  >
                    Route
                  </h3>
                  <p
                    className="mb-3"
                    style={{ fontSize: ".8rem", opacity: ".7" }}
                  >
                     Please provide the little information below and submit
                  </p>
                  <div style={{ width: "85%" }}>
                    <label htmlFor="state">Route Departure</label>
                    {/* <Select
                      options={optionState}
                      handleChange={(e) => setDeparture(e.value)}
                      value={departure}
                      placeholder="Enter departure state and route"
                    /> */}
                    <GooglePlacesAutocomplete
                      apiKey={apiKey}
                      selectProps={{
                        onChange: (value) => {
                          setDeparture(value);
                          setDepartureState(value.value.terms[2].value);
                        },
                        styles: {
                          option: (provided, state) => ({
                            ...provided,
                            borderBottom: "1px dotted black",
                            color: state.isSelected ? "white" : "black",
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? "black" : "white",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? "#b4afba" : "#b4afba",
                          }),
                        },
                        placeholder: "Enter Departure Route...",
                      }}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["ng"],
                        },
                      }}
                    />
                    <br />
                    <label htmlFor="state">Destination Route</label>
                    {/* <Select
                      options={optionState}
                      handleChange={(e) => setArrival(e.value)}
                      value={arrival}
                      placeholder="Enter arrival state and route"
                    /> */}
                    <GooglePlacesAutocomplete
                      apiKey={apiKey}
                      selectProps={{
                        onChange: (value) => {
                          setArrival(value);
                          // setArrivalState(value.value.terms[2]?.value);
                          setArrivalState(value.value.terms[2]?.value || value.value?.description);
                        },
                        styles: {
                          option: (provided, state) => ({
                            ...provided,
                            borderBottom: "1px dotted black",
                            color: state.isSelected ? "white" : "black",
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? "black" : "white",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? "#b4afba" : "#b4afba",
                          }),
                        },
                        placeholder: "Enter Arrival Route...",
                      }}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["ng"],
                        },
                      }}
                    />
                    <br />
                    {loggedInUser === null ? (
                      <>
                        <label htmlFor="fullName" className="label-auth">
                          Full Name
                        </label>
                        <InputField
                          type="text"
                          placeholder="Enter Full Name"
                          onChangeMethod={(e) => setFullName(e.target.value)}
                          value={fullName}
                          customStyles={{
                            fontSize: "10px",
                            color: "white",
                            opacity: ".7",
                          }}
                        />

                        <br />
                        <br />

                        <label htmlFor="email" className="label-auth">
                          Email
                        </label>
                        <InputField
                          type="email"
                          placeholder="example@gmail.com"
                          onChangeMethod={(e) => setEmail(e.target.value)}
                          value={email}
                          customStyles={{
                            fontSize: "10px",
                            color: "white",
                            opacity: ".7",
                          }}
                        />
                        <br />
                        <br />

                        <label htmlFor="phone" className="label-auth">
                          Phone Number
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          defaultCountry="NG"
                          value={value}
                          onChange={setValue}
                          style={{ color: "white" }}
                        />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <Button
                      text={loading ? <Loader dark={false} /> : "Submit"}
                      type="button"
                      handleButtonClick={checkEmail}
                      btnstyle={{
                        backgroundColor: "#E21D00",
                        margin: "20px 0px",
                      }}
                      disabled={
                        !(value && departure && arrival && email && fullName)
                      }
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Appinstall />
      <Footer />
    </div>
  );
};
export default SuggestedRouteComponent;

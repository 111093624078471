import React, { useEffect, useState } from "react";

import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import etisalat from "../../../../../assets/img/9mobile.png";
import paystack from "../../../../../assets/img/Paystack-CeruleanBlue-StackBlue-HL.png";
import airtel from "../../../../../assets/img/airtel.png";
import azaPayLogo from "../../../../../assets/img/azapay-logo.png";
import budPayImg from "../../../../../assets/img/budPay.jpeg";
import flutterwave from "../../../../../assets/img/flutterwave_logo_color.svg";
import glo from "../../../../../assets/img/glo.png";
import mtn from "../../../../../assets/img/mtn.png";
import nb from "../../../../../assets/img/nb.png";
import wallet from "../../../../../assets/img/wallet-icon.png";
import errorIcon from "../../../../../assets/img/x-circle.png";
import Button from "../../../../../components/Button";
import SelectComponent from "../../../../../components/Dropdown";
import InputField from "../../../../../components/InputField";
import Loader from "../../../../../components/Loader";
import ModalComponent from "../../../../../components/Modal";
import ButtonComponent from "../../../../../components/otpinput";
import apiroutes from "../../../../../services/apiroutes";
import { requestTicketMan } from "../../../../../services/apiserviceTicketMan";
import { requestWallet } from "../../../../../services/apiserviceWallet";
import {
  getAuthPaybills,
  getAuthWallet,
  getUser,
  getUserWallet,
} from "../../../../../services/auth";
import makeAPICallAzaPay from "../../../../../services/azaPay";
import makeAPICall from "../../../../../services/paystackPay";
import "./index.css";

const AirtimeTopup = ({
  setShowAirtimeTopup,
  showAirtimeTopup,
  setShowBillsPaymentCard,
  generatedPaymentRef,
  walletState
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // getCurrentWallet();
    // generatePaymentRef();
  }, []);
  const history = useHistory();
  const loggedInUser = getUser();
  const walletDetails = getAuthWallet();
  const walletUser = getUserWallet();
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [showAirtimeTopupProceed, setShowAirtimeTopupProceed] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  // const [airtimeRef, setAirtimeRef] = useState("");
  const [userProvider, setUserProvider] = useState("");
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [walletState, setWalletState] = useState(
  //   JSON.parse(localStorage.getItem("walletState"))
  // );
  const [pageLoading, setPageLoading] = useState(false);
  const [modalVisiblePayment, setModalVisiblePayment] = useState(false);
  const [modalVisibleAmount, setVisibleAmount] = useState(false);
  const [modalVisiblePin, setVisiblePin] = useState(false);
  const [loader, setLoader] = useState(true);
  const [modalVisiblePinPin, setVisiblePinPin] = useState(false);
  const [modalVisible, setVisible] = useState(false);
  const [otpInput, setOtpInput] = useState();

  const nf = new Intl.NumberFormat();

  const providerOptions = [
    { name: "MTN", value: "MTN Nigeria" },
    { name: "Airtel", value: "Airtel Nigeria" },
    { name: "Glo", value: "Glo Nigeria" },
    { name: "Etisalat", value: "Etisalat Nigeria" },
  ];

  const paymentOptions = [
    // { id: 22, name: "woven" },
    // { id: 25, name: "budPay" },
    // { id: 8, name: "flutterwave" },
    { id: 5, name: "paystack" },
    // { id: 26, name: "azaPay" },
    { id: 23, name: "wallet" },
  ];

  const airtimeProviders = providerOptions.map((x) => ({
    label: x.name,
    value: x.value,
  }));

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
    }),
  };

  const sendPayAdvice = async () => {
    // setShowAirtimeTopupProceed(false);
    setPageLoading(true);
    let paybills = true;
    const token = getAuthPaybills();
    const payload = {
      amount,
      customerEmail: loggedInUser.Email,
      customerId: loggedInUser.PhoneNumber,
      customerMobile: "00000000001",
      paymentCode: "10901",
      paymentMethod: 1,
      requestReference: generatedPaymentRef,
      terminalId: "3DMO0001",
      ticketerEmail: loggedInUser.Email,
      transactionType: 0,
    };

    requestTicketMan(apiroutes.SendPayAdvice(), "post", payload, token)
      .then((res) => {
        setPageLoading(false);
        setModalVisiblePayment(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `Airtime Purchased Successfully.`,
          icon: "success",
        });
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch((err) => {
        setPageLoading(false);
        setModalVisiblePayment(false);
      });
  };
  const validatePhoneNumber = async () => {
    setLoadingProvider(true);
    let networkLookup = true;

    requestWallet(
      apiroutes.LookupNetwork(phoneNumber),
      "get",
      null,
      null,
      null,
      networkLookup
    )
      .then((res) => {
        setLoadingProvider(false);

        setUserProvider(res.data.network);
      })
      .catch((err) => {
        setLoadingProvider(false);
        console.log(err.response);
      });
  };

  const payPostSearch = (items) => {
    setPageLoading(true);
    setShowAirtimeTopupProceed(false);
    // setLoading(true);

    if (items.id === 5) {
      setPageLoading(true);
      paystackPay(generatedPaymentRef, amount);
    } else if (items.id === 8) {
      setPageLoading(true);
      history.push("/pay-flutter");
    } else if (items.id === 25) {
      setPageLoading(true);
      history.push("/pay-budpay");
    } else if (items.id === 26) {
      azaPay(generatedPaymentRef, amount);
    } else if (items.id === 23) {
      walletPay(amount);
    } else {
      return false;
    }
  };

  const payWallet = () => {
    toggleModalPinClose();
    setLoader(true);

    const formEncoded = new URLSearchParams({
      Email: loggedInUser.Email,
      Amount: amount,
      PhoneNumber: loggedInUser.PhoneNumber,
      WalletId: walletUser?.id,
      Reference: generatedPaymentRef,
      TransactionDesc: "Bills Payment",
      Pin: otpInput,
    });
    // setTimeout(() => history.push("/confirmed-wallet-payment"), 1500);
    requestWallet(
      apiroutes.DebitWallet(),
      "post",
      formEncoded,
      walletDetails.access_token
    )
      .then((res) => {
        if (res.data.Description === "Successful") {
          sendPayAdvice();
          // history.push("/confirmed-wallet-payment");
        }
      })
      .catch((err) => {
        // alert(err.response.data.description)
        setPageLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.response.data.Description,
          icon: "error",
        });
        // setTimeout(() => {history.push("/confirmed-wallet-payment")}, 2000)
      });
  };

  const walletPay = (amount) => {
    if (walletUser?.availableBalance < amount) {
      toggleModalAmount();
    } else {
      toggleModalPin();
      // payWallet(refCode, amount);
    }
  };

  const azaPay = (refCode, amount) => {
    const configLoad = {
      // customerEmail: email,
      // customerName: fullName,
      // customerPhone: valueTwo,
      ref: refCode,
      amount: JSON.stringify(amount),
    };
    localStorage.setItem("azaPayload", JSON.stringify(configLoad));
    return makeAPICallAzaPay({
      Payload: configLoad,
      method: "POST",
    })
      .then((result) => {
        if (result.status === 200) {
          localStorage.setItem(
            "azaPayAccountDetails",
            JSON.stringify(result.data)
          );
          history.push("/aza-pay");
          // window.location.href = result.data.authorization_url;
        }
      })
      .catch((err) => console.log(err));
  };

  const paystackPay = (refCode, amount) => {
    setPageLoading(true);
    const paybillsPayload = {
      amount,
      refCode,
    };

    localStorage.setItem("paybillsPayload", JSON.stringify(paybillsPayload));
    const configLoad = {
      reference: refCode,
      email: loggedInUser.Email,
      amount: amount * 100,
      // callback_url: "http://localhost:3000/call-back",
      callback_url: process.env.REACT_APP_FALL_BACK_ENDPOINT_PAYBILL,
      // callback_url: config.FALL_BACK_ENDPOINT,
      onClose: process.env.REACT_APP_FALL_BACK_ENDPOINT_PAYBILL,
      // onClose: config.FALL_BACK_ENDPOINT,
    };
    return makeAPICall({
      payload: configLoad,
      method: "POST",
    })
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          window.location.href = `https://checkout.paystack.com/${result.data.access_code}`;
        }
      })
      .catch((err) => {
        console.log(err);
        // alert(err);
        // alert(err.response.ShortDeescription)
      });
  };

  const toggleModalClose = () => {
    setShowAirtimeTopupProceed(false);
    setShowAirtimeTopup(true);
  };

  const toggleModalAmountClose = () => {
    setVisibleAmount(false);
    setPageLoading(false);
    setModalVisiblePayment(false);
    // window.location.reload();
  };

  const toggleModalAmount = () => {
    setVisibleAmount(true);
    toggleModalClose();
  };

  const toggleModalPin = () => {
    setVisiblePin(true);
    toggleModalClose();
  };
  const toggleModalPayOptions = () => {
    setModalVisiblePayment(true);
    setShowAirtimeTopupProceed(false);
  };

  const toggleModalPinClose = () => {
    setVisiblePin(false);
    // history.push("/confirmed-wallet-payment");
  };

  const toggleModalPaymentClose = () => {
    setModalVisiblePayment(false);
  };

  const toggleModalClosePin = () => {
    setVisiblePin(true);
    setVisiblePinPin(false);
    setVisible(false);
  };

  const toggleModalPinPin = () => {
    setVisible(false);
    setVisiblePin(false);
    setVisiblePinPin(true);
  };

  const onChangeOtp = (otpInput) => {
    setOtpInput(otpInput);
  };

  const width = 400;
  const height = 370;
  const heightPay = 530;
  const heightThree = 300;
  const modalPar = `You are about to pay for ₦${nf.format(amount)} Airtime`;
  const modalTitlePinPin = "Transaction PIN";
  const modalParPinPin = "Set up your one time, 4-digit Transaction PIN";
  const modalTitle = (
    <p style={{ fontSize: "1.2rem", marginTop: "20px" }}>Buy Airtime</p>
  );
  const modalBody = (
    <>
      <div className="topup-details">
        <div className="topup-detail">
          <h6 className="topup-detail-label">Amount</h6>
          <h6 className="topup-detail-value">₦{nf.format(amount)}</h6>
        </div>
        <div className="topup-detail">
          <h6 className="topup-detail-label">Network</h6>
          <h6 className="topup-detail-value">{userProvider}</h6>
        </div>
        {/* <div className="topup-detail">
          <h6 className="topup-detail-label">Package</h6>
          <h6 className="topup-detail-value">150GB / month</h6>
        </div> */}
        <div className="topup-detail">
          <h6 className="topup-detail-label">Number</h6>
          <h6 className="topup-detail-value">{phoneNumber}</h6>
        </div>
      </div>
      <Button
        type="button"
        text="Pay"
        btnstyle={{
          color: "white",
          backgroundColor: "red",
          // backdropFilter: "blur(10px)",
          borderRadius: "10px",
          lineHeight: "17px",
          width: "100%",
          margin: "30px 0",
        }}
        // disabled={wallet}
        handleButtonClick={() => toggleModalPayOptions()}
      />
    </>
  );

  const modalBodyPayment = (
    <div>
      <div className="text-center">
        <p className="passenger-amt">Amount</p>
        <h3 className="p-amt">₦{nf.format(amount)}</h3>
        <p className="p-nb">
          <img src={nb} alt="" style={{ width: "10px" }} /> Selecting any of the
          payment method will redirect you to their payment platform.
        </p>
        <h2 className="pay-mthod">Select payment method</h2>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          {pageLoading ? (
            <div className="text-center">
              <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className="row">
              {paymentOptions.map((items) => (
                <div className="col-md-6" key={items.id}>
                  <div onClick={() => payPostSearch(items)}>
                    <div>
                      {items.id === 5 ? (
                        <div className="pay-bg">
                          <img src={paystack} alt="" width="80px" />
                        </div>
                      ) : items.id === 8 ? (
                        // ) : items.id === 25 ? (
                        <div className="pay-bg">
                          <img src={flutterwave} alt="" width="100px" />
                        </div>
                      ) : // ) : items.id === 22 ? (
                      //   <div className="pay-bg">
                      //     <img src={woven} alt="" width="100px" />
                      //   </div>
                      items.id === 25 ? (
                        <div className="pay-bg">
                          <img src={budPayImg} alt="" width="100px" />
                        </div>
                      ) : items.id === 26 ? (
                        <div className="pay-bg">
                          <img src={azaPayLogo} alt="" width="100px" />
                        </div>
                      ) : items.id === 23 ? (
                        walletState && (
                          <div className="pay-bg-wallet">
                            <img src={wallet} alt="" width="50px" />{" "}
                            <h4 className="ml-2 mt-1">
                              <b>Wallet</b>
                            </h4>
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const modalBodyPin = (
    <div>
      <div className="row">
        <div className="col-md-12">
          <ButtonComponent
            secret
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            inputStyle={{
              borderRadius: "0.7142857143rem",
              width: "60px",
              height: "60px",
              backgroundColor: "#f8f5f4",
              border: "0.3px solid #c5afad52",
              fontSize: "1.1428571429rem",
              fontFamily: "Cerebri Sans Pro Bold",
              color: "#949494",
              marginBottom: "10px",
            }}
            handlePincomplete={onChangeOtp}
            length={4}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12 col-md-8 offset-md-2">
          <div className="text-center">
            <Button
              type="button"
              handleButtonClick={payWallet}
              text="Proceed"
              disabled={!otpInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
  const modalBodyLowAmount = (
    <div className="row">
      <div className="col-md-12">
        <div className="text-center">
          <img src={errorIcon} alt="" />
        </div>
        <p className="wallet-onboarding-p text-center">
          Your wallet balance is low
        </p>
        <br />
        {/* <div className="row"> */}
        <div className="col-sm-12 col-md-8 offset-md-2">
          <div className="text-center">
            <Button
              type="button"
              handleButtonClick={toggleModalAmountClose}
              text="Try Again"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );

  return (
    <div>
      <ModalComponent
        width={width}
        height={height}
        visible={showAirtimeTopupProceed}
        title={modalTitle}
        body={modalBody}
        handleClose={toggleModalClose}
      />
      <ModalComponent
        width={width}
        height={heightPay}
        visible={modalVisiblePayment}
        title={modalTitle}
        // paragraph={modalPar}
        body={modalBodyPayment}
        handleClose={toggleModalPaymentClose}
      />
      <ModalComponent
        width={width}
        height={height}
        visible={modalVisiblePin}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBodyPin}
        handleClose={toggleModalClosePin}
      />
      <ModalComponent
        width={width}
        height={heightThree}
        visible={modalVisibleAmount}
        body={modalBodyLowAmount}
        handleClose={toggleModalAmountClose}
      />
      <ModalComponent
        width={width}
        height={height}
        visible={modalVisiblePinPin}
        title={modalTitlePinPin}
        paragraph={modalParPinPin}
        // body={modalBodyPinPin}
        // handleClose={toggleModalClosePinPin}
      />
      {showAirtimeTopup && (
        <div className="row row-grid">
          <div className="col-md-12 align-self-center">
            <div className="pay-bills">
              <h2 className="text-center">Top-up Airtime</h2>
              <br />
              <div className="airtime-card">
                <div className="back-arrow">
                  <i
                    class="fa fa-chevron-left back"
                    aria-hidden="true"
                    onClick={() => {
                      setShowAirtimeTopup(false);
                      setShowBillsPaymentCard(true);
                    }}
                    style={{ cursor: "pointer" }}
                  ></i>
                  <h3 className="text-center">Buy Airtime</h3>
                </div>
                <div className="airtime-form">
                  <h6>Most Recents</h6>
                  <div className="recent-cards">
                    <div className="recent-card">
                      <div className="recent-img">
                        <img
                          src={mtn}
                          height="30"
                          width="30"
                          alt="recent-topup-img"
                        />
                      </div>
                      <h6 className="recent-text">08038587000</h6>
                    </div>
                    <div className="recent-card">
                      <div className="recent-img">
                        <img
                          src={etisalat}
                          height="30"
                          width="30"
                          alt="recent-topup-img"
                        />
                      </div>
                      <h6 className="recent-text">08028587000</h6>
                    </div>
                    <div className="recent-card">
                      <div className="recent-img">
                        <img
                          src={glo}
                          height="30"
                          width="30"
                          alt="recent-topup-img"
                        />
                      </div>
                      <h6 className="recent-text">08058587000</h6>
                    </div>
                  </div>
                  <label
                    style={{ fontSize: ".9rem", fontWeight: "500" }}
                    htmlFor="number"
                  >
                    Phone number
                  </label>
                  <InputField
                    type="tel"
                    placeholder="Input your phone number"
                    onChangeMethod={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    // readonly={true}
                  />
                  <br />
                  <br />
                  <label
                    style={{ fontSize: ".9rem", fontWeight: "500" }}
                    htmlFor="number"
                  >
                    Amount
                  </label>
                  <NumberFormat
                    value={amount}
                    placeholder="0.00"
                    thousandSeparator={true}
                    prefix="₦"
                    className="bill-amount"
                    displayType="input"
                    type="text"
                    onValueChange={(values) => {
                      setAmount(values.value);
                      validatePhoneNumber();
                    }}
                  />
                  <br />
                  <br />
                  <h6>Network Provider</h6>
                  {loadingProvider ? (
                    <div className="text-center mt-2">
                      <div className="lds-default">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="recent-cards">
                        {userProvider == "MTN Nigeria" ? (
                          <div className="provider-card">
                            <img
                              src={mtn}
                              height="30"
                              width="30"
                              alt="recent-topup-img"
                            />
                          </div>
                        ) : userProvider == "Airtel Nigeria" ? (
                          <div className="provider-card">
                            <img
                              src={airtel}
                              height="30"
                              width="30"
                              alt="recent-topup-img"
                            />
                          </div>
                        ) : userProvider == "Glo Nigeria" ? (
                          <div className="provider-card">
                            <img
                              src={glo}
                              height="30"
                              width="30"
                              alt="recent-topup-img"
                            />
                          </div>
                        ) : userProvider == "Etisalat Nigeria" ? (
                          <div className="provider-card">
                            <img
                              src={etisalat}
                              height="30"
                              width="30"
                              alt="recent-topup-img"
                            />
                          </div>
                        ) : (
                          <>
                            <div
                              className="provider-card"
                              onClick={() => setUserProvider("MTN Nigeria")}
                            >
                              <img
                                src={mtn}
                                height="30"
                                width="30"
                                alt="recent-topup-img"
                              />
                            </div>
                            <div
                              className="provider-card"
                              onClick={() =>
                                setUserProvider("Etisalat Nigeria")
                              }
                            >
                              <img
                                src={etisalat}
                                height="30"
                                width="30"
                                alt="recent-topup-img"
                              />
                            </div>
                            <div
                              className="provider-card"
                              onClick={() => setUserProvider("Glo Nigeria")}
                            >
                              <img
                                src={glo}
                                height="30"
                                width="30"
                                alt="recent-topup-img"
                              />
                            </div>
                            <div
                              className="provider-card"
                              onClick={() => setUserProvider("Airtel Nigeria")}
                            >
                              <img
                                src={airtel}
                                height="30"
                                width="30"
                                alt="recent-topup-img"
                              />
                            </div>{" "}
                          </>
                        )}
                      </div>
                      <SelectComponent
                        options={airtimeProviders}
                        value={userProvider}
                        defaultValue={userProvider}
                        handleChange={(e) => setUserProvider(e.value)}
                        placeholder={userProvider}
                        styles={colorStyles}
                      />
                    </>
                  )}
                  <br />
                </div>
                <Button
                  type="button"
                  text={loading ? <Loader dark={false} /> : "Proceed"}
                  btnstyle={{
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: "10px",
                    lineHeight: "17px",
                    width: "100%",
                    margin: "30px 0",
                  }}
                  disabled={!(phoneNumber && amount)}
                  handleButtonClick={() => {
                    setShowAirtimeTopupProceed(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirtimeTopup;

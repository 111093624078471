import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getUser } from "../../../services/auth";
import "./index.css";

const SideBar = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (userInfo === null) {
      return false;
    } else {
      setUser(userInfo);
    }
    // eslint-disable-next-line
  }, []);

  const userInfo = getUser();
  return (
    <div className="side-bar">
      <ul className="sidebar-list">
        {user !== null && (
          <>
            <li>
              <NavLink to="/dashboard" exact={true} activeClassName="active">
                <div className="profile-sidebar">Dashboard</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile" exact={true} activeClassName="active">
                <div className="profile-sidebar">Wallet</div>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/bookinghistory"
                exact={true}
                activeClassName="active"
              >
                <div className="profile-sidebar">Booking history</div>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/bills-payment"
                exact={true}
                activeClassName="active"
              >
                <div className="profile-sidebar">Bills Payment</div>
              </NavLink>
            </li> */}
          </>
        )}

        <li>
          <NavLink to="/support" exact={true} activeClassName="active">
            <div className="profile-sidebar">Help &amp; Support</div>
          </NavLink>
        </li>

        <li>
          <NavLink to="/referral" exact={true} activeClassName="active">
            <div className="profile-sidebar">Referral</div>
          </NavLink>
        </li>

        <li>
          <NavLink to="/setting" exact={true} activeClassName="active">
            <div className="profile-sidebar">Setting</div>
          </NavLink>
        </li>
      </ul>
    </div>
    // <div className="container">
    // </div>
  );
};

export default SideBar;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Alert from "../../../components/Alert";
import Expire from "../../../components/Expire";
import Navbar from "../../../components/NavBar/index";
import apiroutes from "../../../services/apiroutes";
import { requestWallet } from "../../../services/apiserviceWallet";
import MobileNavBar from "../MobileNavbar/index";
import SideBar from "../SideBar/index";
import "./dashboard.css";
// import { setAuth } from "../../services/auth";
import newTag from "../../../assets/img/new.png";
// import Footer from "../../../components/Footer";
import ModalComponentPopup from "../../../components/ModalComponentPopup";
import { requestTicketMan } from "../../../services/apiserviceTicketMan";
import {
  getAuthWallet,
  getUser,
  setAuthPaybills,
  setAuthWallet,
} from "../../../services/auth";
import { removeState, removeStateWallet } from "../../../store/removeState";
import BookSeatUser from "../../HomePage/BookSeatUser";
import BookStatus from "../../HomePage/BookingStatus";
import HireBusUser from "../../HomePage/HireBusUser";

export const DashboardComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const reference = urlParams.get("reference");
  const loggedInUser = getUser();
  const walletDetails = getAuthWallet();
  const [modalVisiblePopup, setVisiblePopup] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const nf = new Intl.NumberFormat();

  useEffect(() => {
    if (loggedInUser === null) {
      // history.push("/");
      removeState();
      removeStateWallet();
      return false;
    } else {
      getWalletToken();
      getPaybillsToken();
      localStorage.removeItem("hireEmailAmount");
      localStorage.removeItem("transEmailAmount");
      localStorage.removeItem("estimatedTravelDistance");
      localStorage.removeItem("allHireDetails");
      localStorage.removeItem("selectedHireBuses");
      localStorage.removeItem("busNameQantity");
      localStorage.removeItem("returnPickupDate");
      localStorage.removeItem("userSelect");
      localStorage.removeItem("allTripDetails");
      localStorage.removeItem("selectedBusData");
      localStorage.removeItem("selectedSeats");
      localStorage.removeItem("selectedSeatsReturn");
      localStorage.removeItem("selectedReturnBusData");
      localStorage.removeItem("wovenAccountDetails");
      localStorage.removeItem("walletUserDetails");
      localStorage.removeItem("wovenWalletAccountDetails");
      localStorage.removeItem("walletPin");
      localStorage.removeItem("adults");
      localStorage.removeItem("adultsRound");
      localStorage.removeItem("retainBus");
      localStorage.removeItem("retainBusReturn");
      localStorage.removeItem("forgotEmail");
      localStorage.removeItem("distanceKmReturn");
      localStorage.removeItem("distanceKm");
      localStorage.removeItem("returnHireDate");
      localStorage.removeItem("azaPayload");
      localStorage.removeItem("allBookingStatus");
      // localStorage.removeItem("walletState");
    }
    // eslint-disable-next-line
  }, []);

  const getWalletToken = () => {
    const formEncoded = new URLSearchParams({
      client_id: "mobility",
      grant_type: "client_credentials",
      client_secret: "158ee0ea-c52b-4735-9145-110cd00f37e1",
    });

    requestWallet(apiroutes.GetWalletToken(), "post", formEncoded)
      .then((res) => {
        const data = {
          access_token: res.data.access_token,
          expires_in: res.data.expires_in,
          token_type: res.data.token_type,
        };
        setAuthWallet(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getPaybillsToken = async () => {
    let paybills = true;
    const bodyData = new URLSearchParams({
      username: "it@gigm.com",
      password: "1t@dmin123",
      grant_type: "password",
    });
    requestTicketMan(apiroutes.GetPaybillsToken(), "post", bodyData, null)
      .then((res) => {
        const data = {
          access_token: res.data.access_token,
          expires_in: res.data.expires_in,
          token_type: res.data.token_type,
        };
        setAuthPaybills(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.addMinutes = function (minutes) {
    this.setMinutes(this.getMinutes() + minutes);
    return this;
  };

  const toggleModalPopupClose = () => {
    setVisiblePopup(false);
    toggleTab(1);
  };

  const width2 = 1200;
  const height2 = 650;

  const modalBodyPopup = (
    <div
      style={{
        width: "100%",
        height: "620px",
        objectFit: "contain",
        position: "relative",
      }}
    >
      <iframe
        title="A custom made iframe"
        // LIVE URL FOR FLIGHT BOOKING
        src="https://gigm.otaas.travel/?cpySource=gigmng&affId=gigmng&affCampaign=gigmng&utm_medium=partner"
        // TEST ENV FOR FLIGHT BOOKING
        // src="https://preprodgi.travelstart.com"
        width={
          window.innerWidth >= 900
            ? "1200"
            : window.innerWidth >= 600
            ? "900"
            : "350"
        }
        height={550}
        frameBorder="0"
        // sandbox="allow-scripts allow-modal"
        // loading="eager"
      ></iframe>
      {/* </a> */}
    </div>
  );

  return (
    <div>
      <ModalComponentPopup
        width={width2}
        height={height2}
        visible={modalVisiblePopup}
        // title={modalPar2}
        // paragraph={modalPar2}
        body={modalBodyPopup}
        handleClose={toggleModalPopupClose}
      />
      <Navbar />

      <div className="container">
        <div className="dashboardArea">
          <SideBar />
          <div className="contentarea">
            <MobileNavBar />
            <div className="row justify-content-md-center">
              <div className="col-md-6 col-sm -12">
                {error && (
                  <Expire delay={3000}>
                    <Alert
                      className="alert text-center alert-danger"
                      text={error}
                    />
                  </Expire>
                )}
                {responseMsg && !error && (
                  <Expire delay={3000}>
                    <Alert
                      className="alert text-center alert-primary"
                      text={responseMsg}
                    />
                  </Expire>
                )}
              </div>
            </div>
            <div className="fund-wallet">
              <section
                id="open-positions"
                style={{ background: "#ffffff", borderRadius: "10px" }}
              >
                <div className="positions">
                  <div className="position-filters-user">
                    <label
                      className={
                        toggleState === 1
                          ? "tabb-label active-tabs-user"
                          : "tabb-label"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      Book a seat
                    </label>
                    <label
                      className={
                        toggleState === 2
                          ? "tabb-label active-tabs-user"
                          : "tabb-label"
                      }
                      onClick={() => toggleTab(2)}
                    >
                      Hire a bus
                    </label>
                    <label
                      className={
                        toggleState === 3
                          ? "tabb-label active-tabs-user"
                          : "tabb-label"
                      }
                      onClick={() => toggleTab(3)}
                    >
                      Booking status
                    </label>
                    <label
                      className={
                        toggleState === 4
                          ? "tabb-label active-tabs blink_me"
                          : "tabb-label blink_me"
                      }
                      onClick={() => {
                        toggleTab(4);
                        setVisiblePopup(true);
                        // alert("Flight feature coming soon...");
                      }}
                      style={{ position: "relative", color: "black" }}
                    >
                      <img
                        src={newTag}
                        alt="nw-tag"
                        width="35"
                        height="35"
                        // className="download-btn-btn"
                        loading="lazy"
                        style={{
                          position: "absolute",
                          top: "-20px",
                          left: "30%",
                        }}
                      />
                      Book a flight
                    </label>
                  </div>

                  <div
                    className={
                      toggleState === 1
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post">
                      <BookSeatUser />
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 2
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post">
                      <HireBusUser />
                    </div>
                  </div>

                  <div
                    className={
                      toggleState === 3
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post">
                      <BookStatus user={true} />
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 4
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post"></div>
                  </div>
                  {/* </div> */}
                </div>
              </section>
            </div>
            <br />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
export default DashboardComponent;

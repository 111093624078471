import React, { useState } from "react";
// import Select from "../../components/Dropdown/index";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { useHistory } from "react-router";
import Button from "../../components/Button";
// import { DistanceMatrixService } from '@react-google-maps/api';
import Loader from "../../components/Loader";
import config from "../../configs";
import apiroutes from "../../services/apiroutes";
import { requestBooking } from "../../services/apiservice";
import { getAuth } from "../../services/auth";
// Import React Scrit Libraray to load Google object
import Script from "react-load-script";
import Swal from "sweetalert2";
// import makeAPICall from "../../services/googleMatrix";
// import HireDepartureSearch from "./GooglePlacesAutoComplete/hireDepartureSearch";
// import HireDestinationSearch from "./GooglePlacesAutoComplete/hireDestinationSearch";

export const HireBusUser = () => {
  const history = useHistory();
  const token = getAuth();
  const [startDate, setStartDate] = useState(new Date());
  const [returnStartDate, setReturnStartDate] = useState(new Date());
  const [departure, setValue] = useState(null);
  const [checkbox, setCheckBox] = useState(false);
  const [retainBus, setRetainBus] = useState(false);
  const [checkboxOne, setCheckBoxOne] = useState(false);
  // const [checkboxOne, setCheckBoxOne] = useState(false);
  const [arrival, setArrival] = useState(null);
  const [returnDeparture, setReturnDeparture] = useState(null);
  const [returnArrival, setReturnArrival] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [pickUpLat, setPickUpLat] = useState({});
  const [departureAddress, setDepartureAddress] = useState({});
  const [arrivalAddress, setArrivalAddress] = useState({});
  const [departureAddressReturn, setDepartureAddressReturn] = useState({});
  const [arrivalAddressReturn, setArrivalAddressReturn] = useState({});
  // const [arrivalLat, setArrivalLat] = useState({});
  const [returnPickUpLat, setReturnPickUpLat] = useState({});
  const [returnArrivalLat, setreturnArrivalLat] = useState({});
  const [retainError, setRetainError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const _eQuatorialEarthRadius = 6378.137;
  // const _d2r = Math.PI / 180.0;
  const apiKey = config.GOOGLE_API;

  const handleDepartureSelect = (item) => {
    let newValue = item;
    setValue(newValue.label);
    geocodeByAddress(newValue.label).then((results) =>
      setDepartureAddress(results[0].formatted_address)
    );
  };
  // const handleDepartureSelect = (item) => {
  //   let newValue = item;
  //   setValue(newValue.label);
  //   geocodeByAddress(newValue.label)
  //     .then((results) =>
  //     getLatLng(results[0]))
  //     .then(({ lat, lng }) => {
  //       setPickUpLat({ lat, lng });
  //     });
  // };

  // useEffect(() => {

  //   const hireDepartureGeo = localStorage.getItem("Depature Geometry")
  //   const hireDestinationGeo = localStorage.getItem("Destination Geometry")
  //   setPickUpLat(hireDepartureGeo);
  //   setArrivalLat(hireDestinationGeo);
  // }, [])
  // const hireDepartureGeo = localStorage.getItem("Depature Geometry");
  // const hireDestinationGeo = localStorage.getItem("Destination Geometry");

  const handleArrivalSelect = (item) => {
    let newValue = item;
    setArrival(newValue.label);
    geocodeByAddress(newValue.label).then((results) =>
      setArrivalAddress(results[0].formatted_address)
    );
  };
  // const handleArrivalSelect = (item) => {
  //   let newValue = item;
  //   setArrival(newValue.label);
  //   geocodeByAddress(newValue.label)
  //     .then((results) => getLatLng(results[0]))
  //     .then(({ lat, lng }) => {
  //       setArrivalLat({ lat, lng });
  //     });
  // };

  const handleRoundDepartureSelect = (item) => {
    let newValue = item;
    setReturnDeparture(newValue.label);
    geocodeByAddress(newValue.label).then((results) =>
      setDepartureAddressReturn(results[0].formatted_address)
    );
  };

  const handleRoundArrivalSelect = (item) => {
    let newValue = item;
    setReturnArrival(newValue.label);
    geocodeByAddress(newValue.label).then((results) =>
      setArrivalAddressReturn(results[0].formatted_address)
    );
  };

  // const handleRetain = (e) => {
  //   if (e.target.checked) {
  //     localStorage.setItem(
  //       "retainBus",
  //       JSON.stringify(true)
  //     );
  //     setCheckBoxOne(e.target.checked);
  //   } else {
  //   localStorage.setItem(
  //     "retainBus",
  //     JSON.stringify(false)
  //   );
  //   setCheckBoxOne(false);
  // }
  // };
  const handleRetainReturn = (e) => {
    if (e.target.checked) {
      localStorage.setItem("retainBusReturn", JSON.stringify(true));
      setCheckBox(e.target.checked);
      setRetainError(true);
    } else {
      localStorage.setItem("retainBusReturn", JSON.stringify(false));
      setCheckBox(false);
      setRetainError(false);
    }
  };

  const getDistance = () => {
    setLoading(true);
    requestBooking(
      apiroutes.GetDistance(departureAddress, arrivalAddress),
      "get",
      null,
      token
    )
      .then((res) => {
        // setLoading(false);
        if (res.data.Object === 0) {
          alert(res.data.ShortDescription);
          history.push("/");
        } else {
          localStorage.setItem("distanceKm", JSON.stringify(res.data.data));
          setTimeout(() => postSearch(), 1500);
        }
      })
      .catch((err) => {
        setLoading(false);
        // alert("Error validating your request, Please try again!");
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.response?.data?.shortDescription || `Error validating your request, Please try again!`,
          // text: `Error validating your request, Please try again!`,
          icon: "error",
        });
      });
  };
  const getDistanceReturn = () => {
    setLoading(true);
    requestBooking(
      apiroutes.GetDistance(departureAddressReturn, arrivalAddressReturn),
      "get",
      null,
      token
    )
      .then((res) => {
        // setLoading(false);
        localStorage.setItem("distanceKmReturn", JSON.stringify(res.data.data));
        setTimeout(() => postSearchReturn(), 1500);
      })
      .catch((err) => {
        setLoading(false);
        // alert("Error validating your request, Please try again!");
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.response?.data?.shortDescription || `Error validating your request, Please try again!`,
          // text: `Error validating your request, Please try again!`,
          icon: "error",
        });
      });
  };

  const postSearch = () => {
    // e.preventDefault();
    // let retainBus = localStorage.getItem("retainBus");
    // retainBus = JSON.parse(retainBus);
    let distanceKm = localStorage.getItem("distanceKm");
    distanceKm = JSON.parse(distanceKm);

    setLoading(true);
    const newStartDate = new Date(startDate);
    let date = JSON.stringify(newStartDate);
    date = date.slice(1, 11);

    const details = {
      OnewayPickupDate: date,
      OnewayDistanceApart: parseInt(distanceKm),
      OneWayDropoffLocation: arrival,
      HiredServiceType: "0",
      OnewayPickupLocation: departure,
      IsSleepOver: false,
      // IsSleepOver: retainBus || false,
    };
    localStorage.setItem("estimatedTravelDistance", distanceKm);

    requestBooking(
      apiroutes.HireSearchOneway(date, parseInt(distanceKm), arrival, departure),
      "post",
      null,
      token
    )
      .then((res) => {
        setLoading(false);
        // if (res.data.data === null) {
        if (res.data.code === 1 || res.data.code === "1") {
          // alert(res.data.ShortDescription);

          localStorage.setItem("allHireDetails", JSON.stringify(res.data.data));
          history.push("/select-bus-hire");
        } else {
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
          // window.location.reload();
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err.message}`,
          icon: "error",
        });
      });
  };
  // const postSearch = () => {
  //   // e.preventDefault();
  //   setLoading(true);
  //   const newStartDate = new Date(startDate);
  //   let date = JSON.stringify(newStartDate);
  //   date = date.slice(1, 11);
  //   // const { lat, lng } = pickUpLat;
  //   // const { lat: lat1, lng: lng2 } = arrivalLat;

  //   // initialize services
  //   const service = new window.google.maps.DistanceMatrixService();
  //   const requestInfo = {
  //     origins: [pickUpLat],
  //     destinations: [arrivalLat],
  //     travelMode: "DRIVING",
  //     unitSystem: window.google.maps.UnitSystem.METRIC,
  //     avoidHighways: false,
  //     avoidTolls: false,
  //   };

  //   service.getDistanceMatrix(requestInfo, callback);

  //   function callback(response, status) {
  //     let distance = response.rows[0].elements[0].distance.text.split("km");
  //     const details = {
  //       OnewayPickupDate: date,
  //       OnewayDistanceApart: parseInt(distance[0]),
  //       OneWayDropoffLocation: arrival,
  //       HiredServiceType: "0",
  //       OnewayPickupLocation: departure,
  //       IsSleepOver: false,
  //       // IsSleepOver: checkboxOne,
  //     };
  //     localStorage.setItem("estimatedTravelDistance", distance[0]);

  //     request(apiroutes.HireSearch(), "post", details, token)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.data.Object === null) {
  //           alert(res.data.ShortDescription);
  //           window.location.reload();
  //         } else {
  //           localStorage.setItem(
  //             "allHireDetails",
  //             JSON.stringify(res.data.Object)
  //           );
  //           history.push("/select-bus-hire");
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   }
  // };

  const postSearchReturn = () => {
    // e.preventDefault();
    let retainBusReturn = localStorage.getItem("retainBusReturn");
    retainBusReturn = JSON.parse(retainBusReturn);
    let distanceKm = localStorage.getItem("distanceKmReturn");
    distanceKm = JSON.parse(distanceKm);
    setLoading(true);
    const newStartDate = new Date(returnStartDate);
    let date = JSON.stringify(newStartDate);
    date = date.slice(1, 11);
    const newEndDate = new Date(endDate);
    let dateEndEnd = JSON.stringify(newEndDate);

    // let dateEndEnd = formatDate(newEndDate, "YYYY-MM-DD hh:mm:ss A");
    dateEndEnd = dateEndEnd.slice(1, 11);

    // dateEnd = dateEnd.slice(1, 11);
    localStorage.setItem("returnHireDate", JSON.stringify(dateEndEnd));

    const details = {
      OnewayPickupDate: date,
      OnewayDistanceApart: parseInt(distanceKm),
      OneWayDropoffLocation: returnArrival,
      HiredServiceType: "1",
      OnewayPickupLocation: returnDeparture,

      // ReturnDistanceApart: parseInt(distanceKm),
      ReturnPickupLocation: returnArrival,
      ReturnPickupDate: dateEndEnd,
      // ReturnPickupDate: dateEnd,
      ReturnDropoffLocation: returnDeparture,
      IsSleepOver: retainBusReturn || false,
      // IsSleepOver: checkbox,
    };
    localStorage.setItem("estimatedTravelDistance", distanceKm);
    requestBooking(
      apiroutes.HireSearch(
        date,
        parseInt(distanceKm),
        returnArrival,
        returnDeparture,
        dateEndEnd,
        returnArrival,
        returnDeparture,
        retainBusReturn
      ),
      "post",
      null,
      token
    )
      .then((res) => {
        setLoading(false);
        if (res.data.code === 1 || res.data.code === "1") {
          localStorage.setItem("allHireDetails", JSON.stringify(res.data.data));
          history.push("/select-bus-hire");
        } else {
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err.message}`,
          icon: "error",
        });
      });
  };
  // const postSearchReturn = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const newStartDate = new Date(returnStartDate);
  //   let date = JSON.stringify(newStartDate);
  //   date = date.slice(1, 11);
  //   const newEndDate = new Date(endDate);
  //   let dateEnd = JSON.stringify(newEndDate);
  //   dateEnd = dateEnd.slice(1, 11);
  //   // initialize services
  //   const service = new window.google.map.DistanceMatrixService();
  //   const requestInfo = {
  //     origins: [returnPickUpLat],
  //     destinations: [returnArrivalLat],
  //     travelMode: "DRIVING",
  //     unitSystem: window.google.maps.UnitSystem.METRIC,
  //     avoidHighways: false,
  //     avoidTolls: false,
  //   };
  //   service.getDistanceMatrix(requestInfo, callback);
  //   function callback(response, status) {
  //     let distance = response.rows[0].elements[0].distance.text.split("km");
  //     const details = {
  //       OnewayPickupDate: date,
  //       OnewayDistanceApart: parseInt(distance[0]),
  //       OneWayDropoffLocation: returnArrival,
  //       HiredServiceType: "1",
  //       OnewayPickupLocation: returnDeparture,
  //       ReturnDistanceApart: parseInt(distance[0]),
  //       ReturnPickupLocation: returnArrival,
  //       ReturnPickupDate: dateEnd,
  //       ReturnDropoffLocation: returnDeparture,
  //       IsSleepOver: false,
  //       // IsSleepOver: checkbox,
  //     };
  //     localStorage.setItem("estimatedTravelDistance", distance[0]);
  //     request(apiroutes.HireSearch(), "post", details, token)
  //       .then((res) => {
  //         setLoading(false);
  //         localStorage.setItem(
  //           "allHireDetails",
  //           JSON.stringify(res.data.Object)
  //         );
  //         history.push("/select-bus-hire");
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   }
  // };

  return (
    <div>
      <input id="oneway-hire-user" type="radio" name="tabshire" defaultChecked />
      <label htmlFor="oneway-hire-user" className="oneway-hire-user" style={{color:"black"}}>
        One Way
      </label>

      <input id="roundtrip-hire-user" type="radio" name="tabshire" />
      <label htmlFor="roundtrip-hire-user" className="roundtrip-hire-user" style={{color:"black"}}>
        Round Trip
      </label>
      <br />
      <br />

      <section id="content3-user">
        <div className="row">
          <div className="col-md-12">
            <label className="label-auth" style={{color:"black"}}>Hire From</label>
            {/* <div>
              <HireDepartureSearch />
            </div> */}
            <Script
              src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAztnWnHBWxpPggjiVQeS9nhiBdDHFUmf0&callback=initMap&v=weekly&channel=2"
              async
            ></Script>
            <GooglePlacesAutocomplete
              apiKey={apiKey}
              selectProps={{
                onChange: (value) => handleDepartureSelect(value),
                styles: {
                  option: (provided, state) => ({
                    ...provided,
                    borderBottom: "1px dotted black",
                    color: state.isSelected ? "black" : "black",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "black" : "black",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "black" : "black",
                  }),
                },
                placeholder: "Enter Departure Point...",
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["ng"],
                },
              }}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <label className="label-auth" style={{color:"black"}}>Hire Destination</label>
            {/* <div>
              <HireDestinationSearch />
            </div> */}
            <GooglePlacesAutocomplete
              apiKey={apiKey}
              selectProps={{
                onChange: (value) => handleArrivalSelect(value),
                styles: {
                  option: (provided, state) => ({
                    ...provided,
                    borderBottom: "1px dotted black",
                    color: state.isSelected ? "black" : "black",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "black" : "black",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "black" : "black",
                  }),
                },
                placeholder: "Enter Hire Destination...",
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["ng"],
                },
              }}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <label className="label-auth" style={{color:"black"}}>Departing on</label>
            <DatePicker
              placeholderText="Select Date"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              // minDate={new Date() }
              minDate={addDays(new Date(), 1)}
              maxDate={addDays(new Date(), 14)}
              onChange={(date) => setStartDate(date)}
              readonly={true}
            />
          </div>
        </div>
        <br />
        {/* <div className="row">
          <div className="col-md-12">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1-user"
                checked={checkboxOne}
                // onChange={(e) => setCheckBoxOne(e.target.checked)}
                onChange={(e) => handleRetain(e)
                }
              />
              <label className="label-auth" htmlFor="exampleCheck1-user">
                Retain bus for the night? It
              </label>
            </div>
          </div>
        </div>
        <br /> */}
        <div className="row">
          <div className="col-md-12">
            <Button
              text={
                loading ? (
                  <div
                    style={{
                      display: "flex",
                      margin: "0 auto",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "200px",
                      transform: "translateX(-10px)",
                    }}
                  >
                    <Loader dark={false} />{" "}
                    <span
                      style={{
                        display: "inline-block",
                        transform: "translateX(-25px)",
                      }}
                    >
                      Processing...
                    </span>
                  </div>
                ) : (
                  "Proceed"
                )
              }
              handleButtonClick={() => getDistance()}
              // handleButtonClick={postSearch}
              type="button"
              disabled={!(startDate && departure && arrival)}
              btnstyle={{
                background: "#E21D00",
                color: "white",
                margin: "20px 0px",
                // fontFamily: "Euclid Circular B",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            />
          </div>
        </div>
      </section>

      <section id="content4-user">
        <div className="row">
          <div className="col-md-12">
            <label className="label-auth" style={{color:"black"}}>Hire From</label>
            <GooglePlacesAutocomplete
              apiKey={apiKey}
              selectProps={{
                onChange: (value) => handleRoundDepartureSelect(value),
                styles: {
                  option: (provided, state) => ({
                    ...provided,
                    borderBottom: "1px dotted black",
                    color: state.isSelected ? "white" : "black",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "black" : "white",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "white" : "white",
                  }),
                },
                placeholder: "Enter Departure Point...",
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["ng"],
                },
              }}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <label className="label-auth" style={{color:"black"}}>Hire Destination</label>
            <GooglePlacesAutocomplete
              apiKey={apiKey}
              selectProps={{
                onChange: (value) => handleRoundArrivalSelect(value),
                styles: {
                  option: (provided, state) => ({
                    ...provided,
                    borderBottom: "1px dotted black",
                    color: state.isSelected ? "white" : "black",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "black" : "white",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "white" : "white",
                  }),
                },
                placeholder: "Enter Hire Destination...",
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["ng"],
                },
              }}
            />
          </div>
        </div>
        <br />
        <div className="row row-grid">
          <div className="col-md-6">
            <label className="label-auth" style={{color:"black"}}> Departing on</label>
            <DatePicker
              placeholderText="Select Date"
              dateFormat="dd/MM/yyyy"
              selected={returnStartDate}
              minDate={new Date()}
              maxDate={addDays(new Date(), 14)}
              onChange={(date) => setReturnStartDate(date)}
            />
          </div>
          <div className="col-md-6">
            <label className="label-auth" style={{color:"black"}}>Return</label>
            <DatePicker
              placeholderText="Select Date"
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              minDate={new Date(returnStartDate)}
              maxDate={addDays(returnStartDate, 14)}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-md-12">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1-user"
                checked={checkbox}
                // onChange={(e) => setCheckBox(e.target.checked)}
                onChange={(e) => handleRetainReturn(e)}
              />
              <label className="label-auth" htmlFor="exampleCheck1-user" style={{color:"black"}}>
                Retain bus for the night?
              </label>
              {retainError && (
                <p
                  style={{
                    color: "red",
                    fontSize: ".9rem",
                    fontWeight: "bold",
                  }}
                >
                  N.B: Retaining Bus for night will include additional charges.
                </p>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Button
              text={
                loading ? (
                  <div
                    style={{
                      display: "flex",
                      margin: "0 auto",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "200px",
                      transform: "translateX(-10px)",
                    }}
                  >
                    <Loader dark={false} />{" "}
                    <span
                      style={{
                        display: "inline-block",
                        transform: "translateX(-25px)",
                      }}
                    >
                      Processing...
                    </span>
                  </div>
                ) : (
                  "Proceed"
                )
              }
              handleButtonClick={() => getDistanceReturn()}
              // handleButtonClick={postSearchReturn}
              type="button"
              disabled={
                !(
                  returnStartDate &&
                  endDate &&
                  returnDeparture &&
                  returnArrival
                )
              }
              btnstyle={{
                background: "#E21D00",
                color: "white",
                margin: "20px 0px",
                fontFamily: "inherit",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default HireBusUser;

import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import "./payBudpay.css";
// import InputField from "../../components/InputField";
import { useHistory } from "react-router";
import Loader from "../../components/Loader";
// import { hslToRgb } from "@material-ui/core";
import { closeBudPayPaymentModal, useBudPayPayment } from "budpay-react-v2";
import logo from "../../assets/img/Layer x0020 1.png";




export const PayBudpayComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = JSON.parse(localStorage.getItem("transEmailAmount"));
    setInfo(data)
  }, [])
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const [info, setInfo] = useState("");

  const handleClose = () => {
    alert("Transaction was not completed, window closed.")
    history.push("/bud-pay");
  };


  const config = {
    api_key: "pk_live_gm6vq1tgyx0wlrewx7qijgnwomsgtcxg9s4p7l", // Replace with your secret key
    // api_key: "sk_live_sgahjygly2ytbimzy26h1nsn5noxvzsh2riwj5n", // Replace with your secret key
    reference: info?.refCode, // Please replace with a reference you generated. or remove the line entirely so our API will generate one for
    email: info?.email,
    amount: JSON.stringify(info?.amount),
    // first_name: "Mary",
    // last_name: "Adams",
    currency: "NGN", // Use GHS for Ghana Cedis or USD for US Dollars
  };



  const budPayConfig = {
    ...config,
    text: "Pay with BudPay",
    // callback_url: "https://gigm.com/bud-pay",
    callback_url: "localhost:3000/bud-pay",
    callback: function (response) {
      closeBudPayPaymentModal(); // this will close the modal programmatically
      // this happens after the payment is completed successfully
      alert(
        "Payment complete! Reference: " +
          response.reference +
          ", Status: " +
          response.status
      );
    },
    onClose: handleClose,
  //   onClose: 
    
  //   function (response) {
  //     console.log(response, "NUGAGEE 77");
  //     alert("Transaction was not completed, window closed nugagee.")
  //     window.location.href = response.data.callback_url;
  //     history.push("/bud-pay")
  //     console.log(response, "NUGAGEE 3");
  //     console.log(response.callback_url, "NUGAGEE");
  //     console.log(response.data.callback_url, "NUGAGEE 2");
  //     window.location.href = response.callback_url;
  //   },
  };

  const handleBudPayPayment = useBudPayPayment(budPayConfig);

  return (
    <div>
      <Navbar />
      <section className="">
        <div className="container h-100">
          <div className="flutter row">
            <div className="">
            <img src={logo} alt="" width="150" />
            </div>
            <br/>
            <div className="">
              <Button
                text={loading ? <Loader dark={false} /> : "Pay With BudPay"}
                handleButtonClick={() => {
                  setLoading(true)
                  handleBudPayPayment()
                }}
                type="button"
                btnstyle={{
                  background: "red",
                  color: "white",
                  margin: "20px 0px",
                  // fontFamily: "Euclid Circular B",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  width: "200px"
                }}
                // disabled={!refCode}
              />
            </div>
          </div>
          <br />
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default PayBudpayComponent;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import paystack from "../../../../../assets/img/Paystack-CeruleanBlue-StackBlue-HL.png";
import azaPayLogo from "../../../../../assets/img/azapay-logo.png";
import budPayImg from "../../../../../assets/img/budPay.jpeg";
import dstv from "../../../../../assets/img/dstv.png";
import flutterwave from "../../../../../assets/img/flutterwave_logo_color.svg";
import gotv from "../../../../../assets/img/gotv.png";
import nb from "../../../../../assets/img/nb.png";
import wallet from "../../../../../assets/img/wallet-icon.png";
import errorIcon from "../../../../../assets/img/x-circle.png";
import Button from "../../../../../components/Button";
import SelectComponent from "../../../../../components/Dropdown";
import InputField from "../../../../../components/InputField";
import Loader from "../../../../../components/Loader";
import ModalComponent from "../../../../../components/Modal";
import ButtonComponent from "../../../../../components/otpinput";
import apiroutes from "../../../../../services/apiroutes";
import { requestTicketMan } from "../../../../../services/apiserviceTicketMan";
import { requestWallet } from "../../../../../services/apiserviceWallet";
import {
  getAuthPaybills,
  getAuthWallet,
  getUser,
  getUserWallet,
} from "../../../../../services/auth";
import makeAPICallAzaPay from "../../../../../services/azaPay";
import makeAPICall from "../../../../../services/paystackPay";
import "./index.css";

const TvSubscription = ({
  setShowTvSubscription,
  showTvSubscription,
  setShowBillsPaymentCard,
  generatedPaymentRef,
  walletState,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // getCurrentWallet();
    // generatePaymentRef();
    getAllTvProvider();
  }, []);
  const history = useHistory();
  const token = getAuthPaybills();
  const loggedInUser = getUser();
  const walletDetails = getAuthWallet();
  const walletUser = getUserWallet();
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [showAirtimeTopupProceed, setShowAirtimeTopupProceed] = useState(false);
  const [smartCardNo, setSmartCardNo] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedPlanPaymentCode, setSelectedPlanPaymentCode] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [modalVisiblePayment, setModalVisiblePayment] = useState(false);
  const [tvProviderOptions, setTvProviderOptions] = useState([]);
  const [loadingProviderPlans, setLoadingProviderPlans] = useState(false);
  const [validatingDetails, setValidatingDetails] = useState(false);
  const [providerPlans, setProviderPlans] = useState([]);
  const [validatedDetails, setValidatedDetails] = useState([]);
  const [modalVisibleAmount, setVisibleAmount] = useState(false);
  const [modalVisiblePin, setVisiblePin] = useState(false);
  const [loader, setLoader] = useState(true);
  const [otpInput, setOtpInput] = useState();
  const [modalVisiblePinPin, setVisiblePinPin] = useState(false);
  const [modalVisible, setVisible] = useState(false);

  const nf = new Intl.NumberFormat();

  const paymentOptions = [
    // { id: 22, name: "woven" },
    // { id: 25, name: "budPay" },
    // { id: 8, name: "flutterwave" },
    { id: 5, name: "paystack" },
    // { id: 26, name: "azaPay" },
    { id: 23, name: "wallet" },
  ];

  const optionsData = tvProviderOptions.map((x) => ({
    label: x.billername,
    value: x.billerid,
  }));
  const optionsPlan = providerPlans.map((x) => ({
    label: `${x.paymentitemname} - ₦${nf.format(x.amount)}`,
    value: x.paymentitemid,
    amount: x.amount,
    paymentCode: x.paymentCode,
    name: x.paymentitemname,
  }));

  const getAllTvProvider = async () => {
    const token = getAuthPaybills();
    fetch(
      `https://live.ticketmann.co/api/quickteller/GetBillersByCategory?CategoryId=2`,

      {
        method: "GET",

        mode: "cors",

        cache: "no-cache",

        credentials: "same-origin",

        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setTvProviderOptions(res.payload.billers);

        // Swal.fire({
        //   showConfirmButton: false,
        //   timer: 4000,
        //   text: `Airtime Purchased Successfully.`,
        //   icon: "success",
        // });
      })
      .catch((err) => {
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `Error fetching TV providers.`,
          icon: "error",
        });
      });
  };
  const getProviderPlans = async (billerId) => {
    setLoadingProviderPlans(true);
    const token = getAuthPaybills();
    fetch(
      `https://live.ticketmann.co/api/quickteller/GetBillerPaymentItems?billerId=${billerId}`,

      {
        method: "GET",

        mode: "cors",

        cache: "no-cache",

        credentials: "same-origin",

        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoadingProviderPlans(false);
        setProviderPlans(res.payload.paymentitems);

        // Swal.fire({
        //   showConfirmButton: false,
        //   timer: 4000,
        //   text: `Airtime Purchased Successfully.`,
        //   icon: "success",
        // });
      })
      .catch((err) => {
        setLoadingProviderPlans(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `Error fetching providers plans.`,
          icon: "error",
        });
      });
  };
  const validateCardNo = async () => {
    setValidatingDetails(true);
    const token = getAuthPaybills();
    const payload = JSON.stringify({
      customers: [
        {
          customerId: smartCardNo,
          paymentCode: selectedPlanPaymentCode,
        },
      ],
    });

    fetch(
      `https://live.ticketmann.co/api/quickteller/CustomerValidation`,

      {
        method: "POST",

        mode: "cors",

        cache: "no-cache",

        credentials: "same-origin",

        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,
        },
        body: payload,
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setValidatingDetails(false);
        if (res.payload == {} || res.payload.customers[0].responseCode != "90000") {
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Error validating informations.`,
            icon: "error",
          });
          return;
        }
        setValidatedDetails(res.payload.customers[0]);
        setShowAirtimeTopupProceed(true);
      })
      .catch((err) => {
        setValidatingDetails(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `Error validating informations.`,
          icon: "error",
        });
      });
  };

  const payPostSearch = (items) => {
    setPageLoading(true);
    setShowAirtimeTopupProceed(false);
    // setLoading(true);

    if (items.id === 5) {
      setPageLoading(true);
      paystackPay(generatedPaymentRef, amount);
    } else if (items.id === 8) {
      setPageLoading(true);
      history.push("/pay-flutter");
    } else if (items.id === 25) {
      setPageLoading(true);
      history.push("/pay-budpay");
    } else if (items.id === 26) {
      azaPay(generatedPaymentRef, amount);
    } else if (items.id === 23) {
      walletPay(amount);
    } else {
      return false;
    }
  };

  const azaPay = (refCode, amount) => {
    const configLoad = {
      // customerEmail: email,
      // customerName: fullName,
      // customerPhone: valueTwo,
      ref: refCode,
      amount: JSON.stringify(amount),
    };
    localStorage.setItem("azaPayload", JSON.stringify(configLoad));
    return makeAPICallAzaPay({
      Payload: configLoad,
      method: "POST",
    })
      .then((result) => {
        if (result.status === 200) {
          localStorage.setItem(
            "azaPayAccountDetails",
            JSON.stringify(result.data)
          );
          history.push("/aza-pay");
          // window.location.href = result.data.authorization_url;
        }
      })
      .catch((err) => console.log(err));
  };

  const paystackPay = (refCode, amount) => {
    setPageLoading(true);
    const paybillsPayload = {
      amount,
      refCode,
    };

    localStorage.setItem("paybillsPayload", JSON.stringify(paybillsPayload));
    const configLoad = {
      reference: refCode,
      email: loggedInUser.Email,
      amount: (parseInt(amount) + 100) * 100,
      // callback_url: "http://localhost:3000/call-back",
      callback_url: process.env.REACT_APP_FALL_BACK_ENDPOINT_PAYBILL,
      // callback_url: config.FALL_BACK_ENDPOINT,
      onClose: process.env.REACT_APP_FALL_BACK_ENDPOINT_PAYBILL,
      // onClose: config.FALL_BACK_ENDPOINT,
    };
    return makeAPICall({
      payload: configLoad,
      method: "POST",
    })
      .then((result) => {
        if (result.status === true) {
          window.location.href = `https://checkout.paystack.com/${result.data.access_code}`;
        }
      })
      .catch((err) => {
        console.log(err);
        // alert(err);
        // alert(err.response.ShortDeescription)
      });
  };

  const payWallet = () => {
    toggleModalPinClose();
    setLoader(true);

    const formEncoded = new URLSearchParams({
      Email: loggedInUser.Email,
      Amount: parseInt(amount) + 100,
      PhoneNumber: loggedInUser.PhoneNumber,
      WalletId: walletUser?.id,
      Reference: generatedPaymentRef,
      TransactionDesc: "Bills Payment",
      Pin: otpInput,
    });
    // setTimeout(() => history.push("/confirmed-wallet-payment"), 1500);
    requestWallet(
      apiroutes.DebitWallet(),
      "post",
      formEncoded,
      walletDetails.access_token
    )
      .then((res) => {
        if (res.data.Description === "Successful") {
          sendPayAdvice();
          // history.push("/confirmed-wallet-payment");
        }
      })
      .catch((err) => {
        // alert(err.response.data.description)
        setPageLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.response.data.Description,
          icon: "error",
        });
        // setTimeout(() => {history.push("/confirmed-wallet-payment")}, 2000)
      });
  };

  const walletPay = (amount) => {
    if (walletUser?.availableBalance < amount) {
      toggleModalAmount();
    } else {
      toggleModalPin();
      // payWallet(refCode, amount);
    }
  };

  const sendPayAdvice = async () => {
    // setShowAirtimeTopupProceed(false);
    setPageLoading(true);
    let paybills = true;
    const token = getAuthPaybills();
    const payload = {
      amount,
      customerEmail: loggedInUser.Email,
      customerId: loggedInUser.PhoneNumber,
      customerMobile: "00000000001",
      paymentCode: "10901",
      paymentMethod: 1,
      requestReference: generatedPaymentRef,
      terminalId: "3DMO0001",
      ticketerEmail: loggedInUser.Email,
      transactionType: 0,
    };

    requestTicketMan(apiroutes.SendPayAdvice(), "post", payload, token)
      .then((res) => {
        setPageLoading(false);
        setModalVisiblePayment(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `Airtime Purchased Successfully.`,
          icon: "success",
        });
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch((err) => {
        setPageLoading(false);
        setModalVisiblePayment(false);
        console.log(err.response);
      });
  };

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
    }),
  };

  const toggleModalClose = () => {
    setShowAirtimeTopupProceed(false);
    setShowTvSubscription(true);
  };

  const toggleModalPaymentClose = () => {
    setModalVisiblePayment(false);
  };

  const toggleModalAmountClose = () => {
    setVisibleAmount(false);
    setPageLoading(false);
    setModalVisiblePayment(false);
    // window.location.reload();
  };

  const toggleModalPinClose = () => {
    setVisiblePin(false);
    // history.push("/confirmed-wallet-payment");
  };

  const onChangeOtp = (otpInput) => {
    setOtpInput(otpInput);
  };

  const toggleModalAmount = () => {
    setVisibleAmount(true);
    toggleModalClose();
  };

  const toggleModalClosePin = () => {
    setVisiblePin(false);
    setModalVisiblePayment(false);
    setPageLoading(false);
    // setVisiblePinPin(false);
    // setVisible(false);
  };

  const toggleModalPin = () => {
    setVisiblePin(true);
    toggleModalClose();
  };

  const width = 400;
  const height = 400;
  const heightPay = 530;
  const heightThree = 300;
  const modalTitle = (
    <p style={{ fontSize: "1.2rem", marginTop: "20px" }}>Pay TV Subscription</p>
  );
  const modalPar = `You are about to pay for ₦${nf.format(parseInt(amount) + 100)} Subscription`;

  const modalBody = (
    <>
      <div className="topup-details">
        <div className="topup-detail">
          <h6 className="topup-detail-label">Bill Payment Type</h6>
          <h6 className="topup-detail-value">TV Subscription</h6>
        </div>
        <div className="topup-detail">
          <h6 className="topup-detail-label">Customer Name</h6>
          <h6 className="topup-detail-value">
            {loggedInUser.FirstName} {loggedInUser.LastName}
          </h6>
        </div>
        <div className="topup-detail">
          <h6 className="topup-detail-label">Card Number</h6>
          <h6 className="topup-detail-value">{smartCardNo}</h6>
        </div>
        <div className="topup-detail">
          <h6 className="topup-detail-label">TV Plan</h6>
          <h6 className="topup-detail-value">{selectedProviderName}</h6>
        </div>
        <div className="topup-detail">
          <h6 className="topup-detail-label">Amount</h6>
          <h6 className="topup-detail-value">₦{nf.format(amount)}</h6>
        </div>
        <div className="topup-detail">
          <h6 className="topup-detail-label">Charges</h6>
          <h6 className="topup-detail-value">₦100</h6>
        </div>
      </div>
      <Button
        type="button"
        text="Pay"
        btnstyle={{
          color: "white",
          backgroundColor: "red",
          borderRadius: "10px",
          lineHeight: "17px",
          width: "100%",
          margin: "30px 0",
        }}
        // disabled={wallet}
        handleButtonClick={() => setModalVisiblePayment(true)}
      />
    </>
  );

  const modalBodyPayment = (
    <div>
      <div className="text-center">
        <p className="passenger-amt">Amount</p>
        <h3 className="p-amt">₦{nf.format(amount)}</h3>
        <p className="p-nb">
          <img src={nb} alt="" style={{ width: "10px" }} /> Selecting any of the
          payment method will redirect you to their payment platform.
        </p>
        <h2 className="pay-mthod">Select payment method</h2>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          {pageLoading ? (
            <div className="text-center">
              <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className="row">
              {paymentOptions.map((items) => (
                <div className="col-md-6" key={items.id}>
                  <div onClick={() => payPostSearch(items)}>
                    <div>
                      {items.id === 5 ? (
                        <div className="pay-bg">
                          <img src={paystack} alt="" width="80px" />
                        </div>
                      ) : items.id === 8 ? (
                        // ) : items.id === 25 ? (
                        <div className="pay-bg">
                          <img src={flutterwave} alt="" width="100px" />
                        </div>
                      ) : // ) : items.id === 22 ? (
                      //   <div className="pay-bg">
                      //     <img src={woven} alt="" width="100px" />
                      //   </div>
                      items.id === 25 ? (
                        <div className="pay-bg">
                          <img src={budPayImg} alt="" width="100px" />
                        </div>
                      ) : items.id === 26 ? (
                        <div className="pay-bg">
                          <img src={azaPayLogo} alt="" width="100px" />
                        </div>
                      ) : items.id === 23 ? (
                        walletState && (
                          <div className="pay-bg-wallet">
                            <img src={wallet} alt="" width="50px" />{" "}
                            <h4 className="ml-2 mt-1">
                              <b>Wallet</b>
                            </h4>
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const modalBodyPin = (
    <div>
      <div className="row">
        <div className="col-md-12">
          <ButtonComponent
            secret
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            inputStyle={{
              borderRadius: "0.7142857143rem",
              width: "60px",
              height: "60px",
              backgroundColor: "#f8f5f4",
              border: "0.3px solid #c5afad52",
              fontSize: "1.1428571429rem",
              fontFamily: "Cerebri Sans Pro Bold",
              color: "#949494",
              marginBottom: "10px",
            }}
            handlePincomplete={onChangeOtp}
            length={4}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12 col-md-8 offset-md-2">
          <div className="text-center">
            <Button
              type="button"
              handleButtonClick={payWallet}
              text="Proceed"
              disabled={!otpInput}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const modalBodyLowAmount = (
    <div className="row">
      <div className="col-md-12">
        <div className="text-center">
          <img src={errorIcon} alt="" />
        </div>
        <p className="wallet-onboarding-p text-center">
          Your wallet balance is low
        </p>
        <br />
        {/* <div className="row"> */}
        <div className="col-sm-12 col-md-8 offset-md-2">
          <div className="text-center">
            <Button
              type="button"
              handleButtonClick={toggleModalAmountClose}
              text="Try Again"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );

  return (
    <div>
      <ModalComponent
        width={width}
        height={height}
        visible={showAirtimeTopupProceed}
        title={modalTitle}
        body={modalBody}
        handleClose={toggleModalClose}
      />
      <ModalComponent
        width={width}
        height={heightPay}
        visible={modalVisiblePayment}
        title={modalTitle}
        // paragraph={modalPar}
        body={modalBodyPayment}
        handleClose={toggleModalPaymentClose}
      />
      <ModalComponent
        width={width}
        height={heightThree}
        visible={modalVisibleAmount}
        body={modalBodyLowAmount}
        handleClose={toggleModalAmountClose}
      />
      <ModalComponent
        width={width}
        height={height}
        visible={modalVisiblePin}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBodyPin}
        handleClose={toggleModalClosePin}
      />
      {showTvSubscription && (
        <div className="pay-bills">
          <h2 className="text-center">Tv Subscription</h2>
          <br />
          <div className="airtime-card">
            <div className="back-arrow">
              <i
                class="fa fa-chevron-left back"
                aria-hidden="true"
                onClick={() => {
                  setShowTvSubscription(false);
                  setShowBillsPaymentCard(true);
                }}
                style={{ cursor: "pointer" }}
              ></i>
              <h3 className="text-center">Buy Tv Subscription</h3>
            </div>
            <div className="airtime-form">
              <h6>Most Recents</h6>
              <div className="recent-cards">
                <div className="recent-card">
                  <div className="recent-img">
                    <img
                      src={dstv}
                      height="30"
                      width="30"
                      alt="recent-topup-img"
                    />
                  </div>
                  <h6 className="recent-text">04271922512</h6>
                </div>
                <div className="recent-card">
                  <div className="recent-img">
                    <img
                      src={gotv}
                      height="30"
                      width="30"
                      alt="recent-topup-img"
                    />
                  </div>
                  <h6 className="recent-text">04271922512</h6>
                </div>
                <div className="recent-card">
                  <div className="recent-img">
                    <img
                      src={dstv}
                      height="30"
                      width="30"
                      alt="recent-topup-img"
                    />
                  </div>
                  <h6 className="recent-text">04271922512</h6>
                </div>
              </div>
              <br />
              <label
                style={{ fontSize: ".9rem", fontWeight: "500" }}
                htmlFor="number"
              >
                Tv Provider
              </label>
              <SelectComponent
                options={optionsData}
                value={selectedProvider}
                handleChange={(e) => {
                  getProviderPlans(e.value);
                  setSelectedProvider(e.value);
                }}
                placeholder="Select Tv provider"
                styles={colorStyles}
              />
              <br />
              <br />
              <label
                style={{ fontSize: ".9rem", fontWeight: "500" }}
                htmlFor="number"
              >
                Smart Card Number
              </label>
              <InputField
                type="number"
                placeholder="Enter smart card number"
                onChangeMethod={(e) => setSmartCardNo(e.target.value)}
                value={smartCardNo}
              />
              <br />
              <br />
              {loadingProviderPlans ? (
                <div className="text-center mt-2">
                  <div className="lds-default">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <>
                  <label
                    style={{ fontSize: ".9rem", fontWeight: "500" }}
                    htmlFor="number"
                  >
                    Tv Plan
                  </label>
                  <SelectComponent
                    options={optionsPlan}
                    value={selectedPlan}
                    handleChange={(e) => {
                      setSelectedPlanPaymentCode(e.paymentCode);
                      setSelectedPlan(e.value);
                      setAmount(e.amount);
                      setSelectedProviderName(e.name);
                    }}
                    placeholder="Select Tv plan"
                    styles={colorStyles}
                  />
                </>
              )}
            </div>
            <Button
              type="button"
              text={validatingDetails ? <Loader /> : "Proceed"}
              btnstyle={{
                color: "white",
                backgroundColor: "red",
                borderRadius: "10px",
                lineHeight: "17px",
                width: "100%",
                margin: "30px 0",
              }}
              // disabled={wallet}
              handleButtonClick={() => {
                validateCardNo();
                // setShowAirtimeTopupProceed(true);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TvSubscription;

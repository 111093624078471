import React, { useState, useEffect } from "react";
import Appinstall from "../../components/Appinstall";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import "./pickup.css";
import InputField from "../../components/InputField";
import { pickupData } from "./pickupdata";
import { pickupDataAjah } from "./aja";
import tooltip from "../../assets/img/tooltip.png";
import GoogleMap from "../../components/GoogleMap"

const Pickup = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState("");
  const [pickup, setPickUp] = useState(pickupDataAjah);

  const handleChange = (e) => {
    let newValue = e.target.value;
    const newPickup = pickupData;
    if (!newValue) {
      setPickUp(pickupDataAjah);
    } else {
      let obj = newPickup.filter(
        (o) =>
          o.state.toLowerCase().includes(newValue.toLowerCase().trim()) ||
          o.terminal.toLowerCase().includes(newValue.toLowerCase().trim())
      );
      setPickUp(obj);
    }
    setValue(newValue);
  };
  return (
    <div>
      <Navbar />
      <section className="pickup">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-md-6 col-sm-12">
              <div>
                <div>
                  <div className="wrapper">
                    <h2>
                      Pick-up Locations <img src={tooltip} alt="" />{" "}
                    </h2>
                    <div className="tooltip">
                      <p>
                      Start your journey from any of our registered pick-up locations and skip the trip to a GIGM bus terminal. Save time and money when next you need to travel. Book on the App and opt for the pick-up service and enjoy extra layers of convenience at absolutely no additional charge.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <h1 className="mb-2">Pick-up Locations</h1> */}
                <div className="terminal-input">
                  <InputField
                    type="text"
                    value={value}
                    placeholder="Search for pickup location"
                    onChangeMethod={handleChange}
                    customStyles={{ width: "100%" }}
                  />
                </div>
                {/* butoon row */}
                {/* <div className="pl-3 row mt-3 terminal-btn">
                  <div className="mr-2 mt-2">
                    <Button
                      text="Abia"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 25px",
                      }}
                    />
                  </div>
                  <div className="mr-2 mt-2">
                    <Button
                      text="Ajah"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                  <div className="mr-2 mt-2">
                    <Button
                      text="Akonwojo"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                  <div className="mr-2 mt-2">
                    <Button
                      text="Onitsha"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                  <div className="mr-2 mt-2">
                    <Button
                      text="Auchi"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <Button
                      text="Bayelsa"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                </div> */}
                {/* button row ends */}
                <div className="mt-5">
                  {pickup.map((pickupdatas) => (
                    <div key={pickupdatas.id}>
                      <ol>
                        {pickupdatas.location.map((locations, index) => (
                          <li key={index} className="pickup-location">
                            {locations}
                          </li>
                        ))}
                      </ol>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div>
                <div>
                  <div className="wrapper mt-5">
                    <GoogleMap value={value} />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Appinstall />
      <Footer />
    </div>
  );
};

export default Pickup;

import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React, { Component } from "react";

import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";


export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: "",
      value: this.props.value,

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 6.524379,
        lng: 3.379206,
      },
    };
  }

  handleChange = () => {
    const address = this.state.value;
    this.setState(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {

        // update center state
        this.setState({ mapCenter: latLng });
      })
      .catch((error) => console.error("Error", error));
  };

  

  render() {
    return (
      <div id="googleMaps">
        

        <Map
          google={this.props.google}
          initialCenter={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng,
          }}
          center={{
            lat: this.props.value
              ? this.props.value.lat
              : this.state.mapCenter.lat,
            lng: this.props.value
              ? this.props.value.lng
              : this.state.mapCenter.lng,
          }}
          zoom={18}
        >
          <Marker
            position={{
              lat: this.props.value
                ? this.props.value.lat
                : this.state.mapCenter.lat,
              lng: this.props.value
                ? this.props.value.lng
                : this.state.mapCenter.lng,
            }}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC-xLKCzuNtvOxo5PlLgBjiASpeahwFJr0",
})(MapContainer);

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import downarrow from "../../assets/img/downarrow.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import apiroutes from "../../services/apiroutes";
import { requestBooking } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const HireCallBackComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stateSuccessful, setStateSuccessful] = useState({});
  const [failureState, setFailureState] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const reference = urlParams.get("reference");
  const token = getAuth();
  const history = useHistory();
  const [details, setDetails] = useState({});

  let routeName = stateSuccessful.route || "loading... - loading...";
  const routeFields = routeName.split("-");

  const nf = new Intl.NumberFormat();

  useEffect(() => {
    let data = localStorage.getItem("hireEmailAmount");
    data = JSON.parse(data);
    setDetails(data);

    requestBooking(
      apiroutes.ConfirmHirePayStackPayment(reference),
      "get",
      null,
      token
    )
      .then((res) => {
        if (res.data.code === 1 || res.data.code === "1") {
          // if (res.data.data.response === "Approved") {
          if (res?.data?.data.bookingReference === null) {
            setFailureState(true);
            localStorage.removeItem("estimatedTravelDistance");
            localStorage.removeItem("allHireDetails");
            localStorage.removeItem("selectedHireBuses");
            localStorage.removeItem("busNameQantity");
            localStorage.removeItem("returnPickupDate");
          }
          setStateSuccessful(res.data.data);
          localStorage.removeItem("estimatedTravelDistance");
          localStorage.removeItem("allHireDetails");
          localStorage.removeItem("selectedHireBuses");
          localStorage.removeItem("busNameQantity");
          localStorage.removeItem("returnPickupDate");
        } else {
          setFailureState(true);
          localStorage.removeItem("estimatedTravelDistance");
          localStorage.removeItem("allHireDetails");
          localStorage.removeItem("selectedHireBuses");
          localStorage.removeItem("busNameQantity");
          localStorage.removeItem("returnPickupDate");
        }
      })
      .catch((err) => {
        setFailureState(true);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `An error occured while processing your request.`,
          icon: "error",
        });
      });
  }, [reference, token]);

  return (
    <div>
      <Navbar />
      <section className="suc-err-page">
        {failureState ? (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1" style={{ color: "#E21D00" }}>
                    Failed Booking
                  </h1>
                  <div className="route-card">
                    <div className="d-flex">
                      <p className="left-par">
                        <strong>Departure Point</strong>
                        <br />
                        {routeFields[0]}
                      </p>
                      <p className="left-par ml-auto text-right">
                        <strong>Destination Point</strong>
                        <br />
                        {routeFields[1]}
                      </p>
                    </div>
                  </div>
                  <div className="route-card-red">
                    <div className="d-flex">
                      <p className="left-par">
                        Customer Name
                        <br />
                        <span>{details?.mainName}</span>
                      </p>
                      <p className="left-par ml-auto text-right">
                        Amount Paid
                        <br />
                        <strong>₦{nf.format(details?.amount)}</strong>
                      </p>
                    </div>
                  </div>
                  {/* <div className="route-card-white-r">
                    <div className="d-flex">
                      <p className="left-par">
                        Passenger
                        <br />
                        <span>{details.mainName}</span>
                        
                      </p>
                      <p className="left-par ml-auto text-right">
                        Seats
                        <br />
                        jdjd
                      </p>
                    </div>
                  </div> */}
                  <div className="text-center">
                    <button
                      className="download-btn"
                      style={{ backgroundColor: "#E21D00" }}
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Book Again{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1">Your Ticket ({reference})</h1>
                  <div className="route-card">
                    <div className="d-flex">
                      <p className="left-par">
                        Departure Point
                        <br />
                        <strong>{routeFields[0]}</strong>
                      </p>
                      <p className="left-par ml-auto text-right">
                        Destination Point
                        <br />
                        <strong>{routeFields[1]}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="route-card-blue">
                    <div className="d-flex">
                      <p className="left-par">
                        Customer Name
                        <br />
                        <span>{details?.mainName}</span>
                      </p>
                      <p className="left-par ml-auto text-right">
                        Amount Paid
                        <br />
                        <strong>₦{nf.format(details?.amount)}</strong>
                      </p>
                    </div>
                  </div>
                  {/* <div className="route-card-white">
                    <div className="d-flex">
                      <p className="left-par">
                        Booker Name
                        <br />
                        <span>{details.mainName}</span>
                        
                      </p>
                      <p className="left-par ml-auto text-right">
                        Departure Date
                        <br />
                        {details.date}
                      </p>
                    </div>
                  </div> */}
                  {/* <div className="route-card-white-b">
                     <div className="d-flex">
                       <p className="left-par">
                       Passenger
                         <br />
                         {stateSuccessful.DepartureDate}
                       </p>
                       <p className="left-par ml-auto text-right">
                       Seats
                         <br />
                         {stateSuccessful.SeatNumber}
                       </p>
                     </div>
                   </div> */}
                  <div className="text-center">
                    <button
                      className="download-btn"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Go Home &nbsp; <img src={downarrow} alt="" />
                    </button>
                  </div>
                </div>

                {/* <h3>Reference Code: {reference}</h3> */}
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};
export default HireCallBackComponent;

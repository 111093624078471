// import React, { useEffect } from "react";
// import Footer from "../../components/Footer";
// import Navbar from "../../components/NavBar";
// import "./track-bus.css";

// const TrackBus = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <div>
//       <Navbar />
//       <section className="privacy">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12 text-center">
//               <h1 className="mb-2">Privacy Policy</h1>
//               {/* <p>Personal Information at GIGM.</p> */}
//             </div>
//           </div>
//         </div>
//       </section>
//       <section className="privacy-text">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <p className="cardholder-privacy">
//                 Cardholder privacy is important to GIG Mobility (GIGM). To
//                 better protect the privacy of cardholder data, GIGM provides
//                 this privacy statement explaining the security and handling
//                 practices of cardholder data in the processing of payment card
//                 transactions.
//               </p>
//               <p>
//                 This privacy statement applies to all cardholder data collected
//                 by or submitted to GIGM’s website. Our website will only collect
//                 personally identifiable information and cardholder data
//                 voluntarily provided by cardholders and customers, and will only
//                 use that information for the specific purposes for which it was
//                 provided. GIGM will keep this information strictly confidential,
//                 and will not disclose, sell, or lease the information to third
//                 parties unless required by law, or with the written permission
//                 of the cardholder.
//               </p>
//               <p>
//                 As with most websites used for payment card transaction
//                 services, our web servers collect web session data used to
//                 analyze site trends and gather broad demographic data. GIGM
//                 reserves the right to collect certain technical information of
//                 customers such as operating system, IP address, web browser
//                 software, and URL data through the use of cookies or other
//                 technology not linked to personally identifiable information or
//                 cardholder data.
//               </p>
//               <p>
//                 GIGM-maintained websites may have links to other third-party
//                 sites used for payment card transactions. These third-party
//                 sites may collect cardholder data and personally identifiable
//                 information through the use of forms or cookies, or from the
//                 customer's web browser. Cardholders and customers are strongly
//                 encouraged to review the privacy policies of all third-party
//                 websites outside the control of GIGM for their procedures for
//                 collecting, utilizing, and disclosing cardholder data.
//               </p>
//               <p>
//                 We have made significant investment in security measures
//                 employed to protect cardholder data under our control. Access to
//                 acquired cardholder data and personally identifiable information
//                 is limited to only those personnel for whom there is an
//                 established business need to access that data.
//               </p>
//               <p>
//                 By using our services on our website or mobile application, you
//                 consent that your personal information can be used for the above
//                 purposes.
//               </p>
//               <p>
//                 We shall retain your information as long as it is necessary to
//                 provide the services requested by you and others, subject to any
//                 legal obligations to further retain such information.
//                 Information associated with your account will generally be kept
//                 until it is no longer necessary to provide the services or until
//                 you ask us to delete it or your account is deleted whichever
//                 comes first. Additionally, we may retain information from
//                 deleted accounts to comply with the law, prevent fraud, resolve
//                 disputes, troubleshoot problems, assist with investigations,
//                 enforce the Terms of Use, and take other actions permitted by
//                 law. The information we retain will be handled in accordance
//                 with this Privacy Policy. Finally, your data could also be
//                 stored for sales statistical purposes.
//               </p>
//               <p>
//                 For questions, comments, or concerns regarding this privacy
//                 statement, or GIGM’s procedures for securely processing,
//                 storing, or transmitting cardholder data, please contact us via
//                 email at{" "}
//                 <span style={{ color: "#56CCF2" }}> contact@gigm.com.</span>{" "}
//                 GIGM reserves the right to amend this privacy statement as and
//                 when required.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Footer />
//     </div>
//   );
// };

// export default TrackBus;

import {
  Box,
  // Container,
  Card,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
// import { APIProvider, Map } from "@vis.gl/react-google-maps";
// import ReactGoogleMaps from "@vis.gl/react-google-maps/dist/index.modern.mjs";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import io from "socket.io-client";
// import useSettings from '../../../../hooks/useSettings';
// import Page from '../../../../components/Page';
// import { FilterToolBar } from '../../../../sections/@dashboard/user/list';
// import MapBoard from "../../../../components/map/MapBoard";
import apiroutes from "../../services/apiroutes";
import { requestSetting } from "../../services/apiservice";

export default function TrackBusById() {
  const { t: token, i: imei } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [position, setPosition] = useState({ lat: 0, lng: 0, speed: 0 });
  const [loading, setLoading] = useState(true);
  const [vehicleDetails, setVehicleDetails] = useState({
    route: "",
    estimatedTimeOfArrival: null,
    departureTerminalResponse: {
      latitude: 0.0,
      longitude: 0.0,
      departureTerminal: "Nil",
    },
    destinationTerminalResponse: {
      latitude: 0.0,
      longitude: 0.0,
      destinationTerminal: "Nil",
    },
  });

  const fetchVehicleDetails = useCallback(async () => {
    try {
      const result = await requestSetting(
        apiroutes.TrackVehicleById(imei),
        "get",
        null,
        null
      );
      // httpRequest({
      //   url: `AutoTrack/vehicletrip?vehicleImei=${imei}`,
      //   urlType: 'settings',
      //   applyToken: false,
      // });

      if (result.code === 1) {
        setVehicleDetails(result?.data);
      } else if (result?.shortDescription) {
        enqueueSnackbar(
          result?.shortDescription || "Can't get vehicle locatioon",
          { variant: "error" }
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        error?.message || "Something went wrong, please refresh and try again!",
        { variant: "error" }
      );
    }
  }, [imei, enqueueSnackbar]);

  useEffect(() => {
    fetchVehicleDetails();
  }, [fetchVehicleDetails]);

  const handleSocketConnection = useCallback(() => {
    const socket = io.connect("https://gig-websockets.autotrack.ng", {
      secure: true,
      rejectUnauthorized: false,
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
    });

    const fetchDataAndDisconnect = () => {
      socket.emit("get-location", { imei, val: "" });

      socket.on("location-response", (location) => {
        try {
          const { received_data: receivedData } = location;
          if (receivedData?.status === "ok") {
            setPosition({
              lat: receivedData?.latitude,
              lng: receivedData?.longitude,
              speed: receivedData?.speed,
            });
          } else {
            setPosition({ lat: 0, lng: 0, speed: 0 });
            enqueueSnackbar(receivedData?.msg || "Invalid Information!", {
              variant: "error",
            });
          }
          setLoading(false);
        } catch (error) {
          console.error("Error handling received message:", error);
        } finally {
          socket.disconnect();
        }
      });

      socket.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
        socket.disconnect();
        setLoading(false);
      });
    };

    socket.on("connect", fetchDataAndDisconnect);

    return () => {
      socket.off("connect", fetchDataAndDisconnect);
      socket.disconnect();
    };
  }, [token, imei, enqueueSnackbar]);

  useEffect(() => {
    let timeoutId;

    const startSocketCycle = () => {
      handleSocketConnection();
      timeoutId = setTimeout(startSocketCycle, 5 * 60000); // 5 minutes
    };

    startSocketCycle();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleSocketConnection]);

  // const { themeStretch } = useSettings();

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={11} mx="auto">
        <Card sx={{ px: { xs: 0, sm: 3 }, py: 2, mt: 2 }}>
          {/* {loading ? ( */}
          <Box sx={{ px: 3 }}>
            <LinearProgress />
            <Typography variant="h5" textAlign="center" mt={3}>
              Fetching Bus Location...
            </Typography>
            {/* <GoogleMap /> */}
            {/* <APIProvider apiKey="AIzaSyAbKHd3D0sMt_YI5175r284Oa6xPZh_83A"> */}
            {/* <Map
                  style={{ height: "70vh" }}
                  defaultZoom={9}
                  gestureHandling={"greedy"}
                  disableDefaultUI
                  mapId="80ac36512b533139"
                  tilt={60}
                > */}
            {/* <Direction
                  position={position}
                  vehicleDetails={vehicleDetails}
                /> */}
            {/* </Map> */}
            {/* </APIProvider> */}
          </Box>
          {/* ) : (
            <APIProvider apiKey="AIzaSyAbKHd3D0sMt_YI5175r284Oa6xPZh_83A">
              <Map
                style={{ height: "70vh" }}
                defaultZoom={9}
                gestureHandling={"greedy"}
                disableDefaultUI
                mapId="80ac36512b533139"
                tilt={60}
              >
                <Direction
                  position={position}
                  vehicleDetails={vehicleDetails}
                />
              </Map>
            </APIProvider>
          )} */}
        </Card>
      </Grid>
    </Grid>
  );
}

// const Direction = ({ position, vehicleDetails }) => {
//   const map = useMap();
//   const routesLibrary = useMapsLibrary("routes");

//   const [directionsService, setDirectionsService] = useState();
//   const [directionsRenderer, setDirectionsRenderer] = useState();
//   const [routes, setRoutes] = useState([]);
//   const [routeIndex, setRouteIndex] = useState(0);
//   const selected = routes[routeIndex];
//   const leg = selected?.legs[0];

//   const originCoordinates = useMemo(
//     () => ({ lat: position.lat, lng: position.lng }),
//     [position.lat, position.lng]
//   );
//   const endCoordinates = useMemo(
//     () => ({
//       lat: vehicleDetails?.destinationTerminalResponse?.latitude || 0,
//       lng: vehicleDetails?.destinationTerminalResponse?.longitude || 0,
//     }),
//     [vehicleDetails?.destinationTerminalResponse]
//   );

//   useEffect(() => {
//     if (!routesLibrary || !map) return;
//     setDirectionsService(new routesLibrary.DirectionsService());
//     setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));

//     // Add custom marker for the origin with scaled size
//     new window.google.maps.Marker({
//       position: originCoordinates,
//       map,
//       icon: {
//         // url: '/assets/bus.avif',
//         url: "https://progfams.com/gig/hiace2.png",
//         scaledSize: new window.google.maps.Size(90, 70),
//       },
//     });
//   }, [routesLibrary, map, originCoordinates]);

//   useEffect(() => {
//     if (!directionsRenderer) return;
//     directionsRenderer.setRouteIndex(routeIndex);
//   }, [routeIndex, directionsRenderer]);

//   useEffect(() => {
//     if (!directionsService || !directionsRenderer) return;

//     directionsService
//       .route({
//         origin: originCoordinates,
//         destination: endCoordinates,
//         travelMode: window.google.maps.TravelMode.DRIVING,
//         provideRouteAlternatives: false,
//       })
//       .then((res) => {
//         directionsRenderer.setDirections(res);
//         setRoutes(res.routes);
//       });
//   }, [
//     directionsService,
//     directionsRenderer,
//     originCoordinates,
//     endCoordinates,
//   ]);

//   if (!leg) return null;

//   return (
//     <div
//       style={{
//         backgroundColor: "rgba(50,50,50,0.6)",
//         padding: "20px",
//         width: "220px",
//         position: "absolute",
//         top: "20px",
//         color: "white",
//         right: "20px",
//       }}
//     >
//       <div className="mb-1">
//         <strong className="text-white">{vehicleDetails?.route}</strong>
//       </div>
//       <div className="py-0 my-0">
//         <hr />
//       </div>
//       <small className="mb-1 d-block">
//         <strong>Distance: </strong> {leg?.distance?.text}
//       </small>
//       <small className="mb-1 d-block">
//         <strong>Duration: </strong> {leg?.duration?.text}
//       </small>
//       <small className="mb-1 d-block">
//         <strong>Speed: </strong> {position?.speed || "Not Available"}
//       </small>
//     </div>
//   );
// };

// Direction.propTypes = {
//   position: PropTypes.object,
//   vehicleDetails: PropTypes.object,
// };

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import Navbar from "../../components/NavBar";
import apiroutes from "../../services/apiroutes";
import { requestBooking } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const BnplCallBackComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stateSuccessful, setStateSuccessful] = useState({});
  const [failureState, setFailureState] = useState(false);
  const [seatNumber, setSeatNumber] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const reference = urlParams.get("reference");
  const token = getAuth();
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  let routeName = stateSuccessful?.route || "loading... ==> loading...";
  const routeFields = routeName.split("==>");

  useEffect(() => {
    let data = localStorage.getItem("transEmailAmount");
    data = JSON.parse(data);
    setSeatNumber(data ? data?.seats : "");
    setDetails(data);
    const confirmPayment = {
      email: data ? data?.email : "",
      amount: data ? data?.amount : "",
      RefCode: reference,
      PayStackReference: reference,
    };

    requestBooking(
      apiroutes.ConfirmPayStackPayment(reference),
      "get",
      null,
      token
    )
      .then((res) => {
        if (res.data.code == 1) {
          setStateSuccessful(res?.data?.data);
          localStorage.removeItem("userSelect");
          localStorage.removeItem("allTripDetails");
          localStorage.removeItem("selectedBusData");
          localStorage.removeItem("selectedSeats");
          localStorage.removeItem("selectedSeatsReturn");
          localStorage.removeItem("selectedReturnBusData");
        } else {
          setFailureState(true);
        }
      })
      .catch((err) => {
        setFailureState(true);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `An error occured while processing your request.`,
          icon: "error",
        });
      });
  }, [reference, token]);

  var newDate = new Date(stateSuccessful.departureDate || "1/1/2000");
  var yearOne = newDate.getFullYear();
  var monthOne = newDate.getMonth() + 1; //getMonth is zero based;

  var dayOne = newDate.getDate();
  let formatted = dayOne + "-" + monthOne + "-" + yearOne;

  const getBookingDetails = (e) => {
    e.preventDefault();
    setLoading(true);

    requestBooking(apiroutes.BookingStatus(reference), "get", null, token)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("allBookingStatus", JSON.stringify(res.data.data));
        history.push("/bookingStatus");
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err,
          icon: "error",
        });
      });
  };

  return (
    <div>
      <Navbar />
      <section className="suc-err-page">
        {failureState ? (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1" style={{ color: "#E21D00" }}>
                    Failed Booking
                  </h1>
                  <div className="route-card">
                    <div className="d-flex">
                      <p className="left-par">
                        {routeFields[0]}
                        <br />
                        {stateSuccessful?.departureTime}
                      </p>
                      <p className="left-par ml-auto text-right">
                        {routeFields[1]}
                        <br />
                        {stateSuccessful?.departureTime}
                      </p>
                    </div>
                  </div>
                  <div className="route-card-red">
                    <div className="d-flex">
                      <p className="left-par">
                        Departure date
                        <br />
                        {formatted}
                      </p>
                      <p className="left-par ml-auto text-right">
                        Departure Time
                        <br />
                        {stateSuccessful?.departureTime}
                      </p>
                    </div>
                  </div>
                  <div className="route-card-white-r">
                    <div className="d-flex">
                      <p className="left-par">
                        Passenger
                        <br />
                        <span>{details?.mainName}</span>
                        <br />
                        {!details?.otherNames ||
                        details?.otherNames.length === 0 ? (
                          "&nbsp;"
                        ) : (
                          <>
                            {details?.otherNames.map((item, i) => (
                              <>
                                <span key={i}>{item?.fullName}</span>
                                <br />
                              </>
                            ))}
                          </>
                        )}
                      </p>
                      <p className="left-par ml-auto text-right">
                        Seats
                        <br />
                        <strong>{seatNumber?.toString()}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className="download-btn"
                      style={{ backgroundColor: "#E21D00" }}
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Book Again{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1">Your Ticket ({reference})</h1>
                  <div className="route-card">
                    <div className="d-flex">
                      <p className="left-par">
                        Departure
                        <br />
                        <strong>{routeFields[0]}</strong>
                        {/* {stateSuccessful.DepartureTime} */}
                      </p>
                      <p className="left-par ml-auto text-right">
                        Destination
                        <br />
                        <strong>{routeFields[1]}</strong>
                        {/* {stateSuccessful.DepartureTime} */}
                      </p>
                    </div>
                  </div>
                  <div className="route-card-blue">
                    <div className="d-flex">
                      <p className="left-par">
                        Departure date
                        <br />
                        <strong>{formatted}</strong>
                      </p>
                      <p className="left-par ml-auto text-right">
                        Departure Time
                        <br />
                        <strong>{stateSuccessful?.departureTime}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="route-card-white">
                    <div className="d-flex">
                      <p className="left-par">
                        Passenger
                        <br />
                        <span>{details?.mainName}</span>
                        <br />
                        {!details?.otherNames ||
                        details?.otherNames.length === 0 ? (
                          "&nbsp;"
                        ) : (
                          <>
                            {details?.otherNames.map((item, i) => (
                              <>
                                <span key={i}>{item?.fullName}</span>
                                <br />
                              </>
                            ))}
                          </>
                        )}
                      </p>
                      <p className="left-par ml-auto text-right">
                        Seats
                        <br />
                        <strong>{seatNumber?.toString()}</strong>
                      </p>
                    </div>
                  </div>
                  {/* <div className="route-card-white-b">
                     <div className="d-flex">
                       <p className="left-par">
                       Passenger
                         <br />
                         {stateSuccessful.DepartureDate}
                       </p>
                       <p className="left-par ml-auto text-right">
                       Seats
                         <br />
                         {stateSuccessful.SeatNumber}
                       </p>
                     </div>
                   </div> */}
                  <div className="text-center">
                    <Button
                      handleButtonClick={getBookingDetails}
                      text={
                        loading ? (
                          <Loader dark={false} />
                        ) : (
                          "Check Booking Details"
                        )
                      }
                      type="button"
                      btnstyle={{
                        background: "#E21D00",
                        color: "white",
                        margin: "20px 0px",
                        fontFamily: "Euclid Circular B",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                </div>

                {/* <h3>Reference Code: {reference}</h3> */}
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};
export default BnplCallBackComponent;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import downarrow from "../../assets/img/downarrow.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import apiroutes from "../../services/apiroutes";
import { requestBooking } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const PaymentComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let walletData = localStorage.getItem("walletPin");
    walletData = JSON.parse(walletData);
    const confirmPayment = {
      email: walletData?.Email,
      refCode: walletData?.Reference,
      pin: walletData?.Pin,
      phone: walletData?.PhoneNumber,
      description: walletData?.TransactionDesc,
      walletType: parseInt(walletData?.WalletToDebit),
    };

    requestBooking(apiroutes.ProcessWallet(), "post", confirmPayment, token)
      .then((res) => {
        if (res.data.code === 1 || res.data.code === "1") {
          setLoader(false);
          setFailureState(false);
          // setApproval(res.data?.Object);
        } else {
          setLoader(false);
          setFailureState(true);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: res?.data?.shortDescription,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        setFailureState(true);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.message == "Request failed with status code 400" ? "Wrong pin supplied. Your wallet will be locked on 3 failed attempts" : err.message,
          icon: "error",
        });
      });
  }, []);

  // const [data, setData] = useState({});
  // const [approval, setApproval] = useState({});
  const [failureState, setFailureState] = useState(true);
  const [loader, setLoader] = useState(true);
  const token = getAuth();

  const history = useHistory();
  let data = localStorage.getItem("transEmailAmount");
  data = JSON.parse(data);
  let seatData = localStorage.getItem("selectedSeats");
  seatData = JSON.parse(seatData);
  let busData = localStorage.getItem("selectedBusData");
  busData = JSON.parse(busData);

  const routeFields = busData?.routeName.split("==>");

  var newDate = new Date(busData?.departureDate);
  var yearOne = newDate.getFullYear();
  var monthOne = newDate.getMonth() + 1; //getMonth is zero based;

  var dayOne = newDate.getDate();
  let formatted = dayOne + "-" + monthOne + "-" + yearOne;

  return (
    <div>
      {loader === false ? (
        <>
          <Navbar />
          <section className="suc-err-page">
            {failureState === true ? (
              <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10 col-md-8 col-lg-6">
                    <div className="ticket-card">
                      <h1 className="route-card-h1">
                        Your Booking was Cancelled!!!
                      </h1>
                      <div className="text-center">
                        <button
                          className="download-btn"
                          onClick={() => {
                            history.push("/");
                          }}
                        >
                          Go Home &nbsp; <img src={downarrow} alt="" />
                        </button>
                      </div>
                    </div>

                    {/* <h3>Reference Code: {reference}</h3> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10 col-md-8 col-lg-6">
                    <div className="ticket-card">
                      <h1 className="route-card-h1">
                        Your Ticket ({data?.refCode})
                      </h1>
                      <div className="route-card">
                        <div className="d-flex">
                          <p className="left-par">
                            Departure
                            <br />
                            <strong>{routeFields[0]}</strong>
                            {/* {busData.DepartureTime} */}
                          </p>
                          <p className="left-par ml-auto text-right">
                            Destination
                            <br />
                            <strong>{routeFields[1]}</strong>
                            {/* {busData.DepartureTime} */}
                          </p>
                        </div>
                      </div>
                      <div className="route-card-blue">
                        <div className="d-flex">
                          <p className="left-par">
                            <strong>Departure date</strong>
                            <br />
                            {formatted}
                          </p>
                          <p className="left-par ml-auto text-right">
                            <strong>Departure Time</strong>
                            <br />
                            {busData?.departureTime}
                          </p>
                        </div>
                      </div>
                      <div className="route-card-white">
                        <div className="d-flex">
                          <p className="left-par">
                            Passenger
                            <br />
                            <span>{data?.mainName}</span>
                            <br />
                            {!data?.otherNames ||
                            data?.otherNames.length === 0 ? (
                              "&nbsp;"
                            ) : (
                              <>
                                {data?.otherNames.map((item, i) => (
                                  <>
                                    <span key={i}>{item.fullName}</span>
                                    <br />
                                  </>
                                ))}
                              </>
                            )}
                          </p>
                          <p className="left-par ml-auto text-right">
                            Seats
                            <br />
                            <strong>{seatData?.toString()}</strong>
                          </p>
                        </div>
                      </div>
                      {/* <div className="route-card-white-b">
                     <div className="d-flex">
                       <p className="left-par">
                       Passenger
                         <br />
                         {stateSuccessful.DepartureDate}
                       </p>
                       <p className="left-par ml-auto text-right">
                       Seats
                         <br />
                         {stateSuccessful.SeatNumber}
                       </p>
                     </div>
                   </div> */}
                      <div className="text-center">
                        <button
                          className="download-btn"
                          onClick={() => {
                            history.push("/");
                          }}
                        >
                          Go Home &nbsp; <img src={downarrow} alt="" />
                        </button>
                      </div>
                    </div>

                    {/* <h3>Reference Code: {reference}</h3> */}
                  </div>
                </div>
              </div>
            )}
          </section>

          <Footer />
        </>
      ) : (
        <>
          {/* <p>Loading</p> */}
          <div
            style={{ background: "#67656500", paddingTop: "400px" }}
            className="text-center"
          >
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default PaymentComponent;

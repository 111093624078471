import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import apiroutes from "../../services/apiroutes";
import { requestTicketMan } from "../../services/apiserviceTicketMan";
import { getAuthPaybills, getUser } from "../../services/auth";

export const PayBillsCallBackComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stateSuccessful, setStateSuccessful] = useState({});
  const [failureState, setFailureState] = useState(false);
  const location = useLocation();
  const token = getAuthPaybills();
  const urlParams = new URLSearchParams(location.search);
  const reference = urlParams.get("reference");
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [loader, setLoader] = useState(true);
 


  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("paybillsPayload"));
    // data);
    setDetails(data);
    let paybills = true;
    const loggedInUser = getUser();
    const payload = {
      amount: data?.amount,
      customerEmail: loggedInUser?.Email,
      customerId: loggedInUser?.PhoneNumber,
      customerMobile: "00000000001",
      paymentCode: "10901",
      paymentMethod: 0,
      requestReference: data?.refCode,
      terminalId: "3DMO0001",
      ticketerEmail: loggedInUser?.Email,
      transactionType: 0,
    };

    requestTicketMan(apiroutes.SendPayAdvice(), "post", payload, token)
      .then((res) => {
        setLoader(false)
        // if (res.data.code == 1) {
        //   setStateSuccessful(res?.data?.data);
        // } else {
          setFailureState(false);
        // }
      })
      .catch((err) => {
        setLoader(false)
        setFailureState(true);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `An error occured while processing your request.`,
          icon: "error",
        });
      });
  }, [reference, token]);


  return (
    <div>
      {loader === false ? (
      <>
      <Navbar />
      <section className="suc-err-page">
        {failureState ? (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1" style={{ color: "#E21D00" }}>
                    Failed Payment
                  </h1>
             
                  <div className="text-center">
                    <button
                      className="download-btn"
                      style={{ backgroundColor: "#E21D00" }}
                      onClick={() => {
                        history.push("/bills-payment");
                      }}
                    >
                      Try Again{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1">Your Payment of ({reference}) was successful.</h1>
            
                  <div className="text-center">
                    <button
                      className="download-btn"
                      style={{ backgroundColor: "#E21D00" }}
                      onClick={() => {
                        history.push("/bills-payment");
                      }}
                    >
                      Buy Again{" "}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
      </>
      ) : (
        <>
          <div
            style={{ background: "#67656500", paddingTop: "400px" }}
            className="text-center"
          >
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </div>

  );
};
export default PayBillsCallBackComponent;
